import styles from './NavBarModalSection.module.css';

interface NavBarModalSectionProps {
    children: React.ReactNode
}

export default function NavBarModalSection({ children }: NavBarModalSectionProps) {
    return (
        <div className={styles['navbar-modalSection']}>
            {children}
        </div>
    );
}
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import loadable from '@loadable/component';
import NavBar from './components/navBar/NavBar';
import Footer from './layout/footer/Footer';
import { TokenSetter } from './TokenSetter';
import HomePage from './pages/HomePage';
import links from './data/links.json';
import { PageLoaderContainer } from './layout/pageContainer/PageContainer';

export const AppRouter = () => {

  const LoadingPageFallback =
    <PageLoaderContainer />;

  const ScannerPage = loadable(() => import('./pages/ScannerPage'), {
    fallback: LoadingPageFallback,
  });
  const StrategyCreatorPage = loadable(() => import('./pages/StrategyCreatorPage'), {
    fallback: LoadingPageFallback,
  });
  const InstrumentsPage = loadable(() => import('./pages/InstrumentsPage'), {
    fallback: LoadingPageFallback,
  });
  const PortfolioPage = loadable(() => import('./pages/PortfolioPage'), {
    fallback: LoadingPageFallback,
  });
  const UserPanelPage = loadable(() => import('./pages/UserPanelPage'), {
    fallback: LoadingPageFallback,
  });
  const UserPortfoliosPage = loadable(() => import('./pages/UserPortfoliosPage'), {
    fallback: LoadingPageFallback,
  });
  const SamplePortfoliosPage = loadable(() => import('./pages/SamplePortfoliosPage'), {
    fallback: LoadingPageFallback,
  });
  const ShareholderPage = loadable(() => import('./pages/ShareholderPage'), {
    fallback: LoadingPageFallback,
  });
  const NothingPage = loadable(() => import('./pages/NothingPage'), {
    fallback: LoadingPageFallback,
  });
  const AdminStrategiesPage = loadable(() => import('./pages/AdminStrategiesPage'), {
    fallback: LoadingPageFallback,
  });
  const AdminInstrumentsPage = loadable(() => import('./pages/AdminInstrumentsPage'), {
    fallback: LoadingPageFallback,
  });
  const AdminUploadReportPage = loadable(() => import('./pages/AdminUploadReportPage'), {
    fallback: LoadingPageFallback,
  });
  const HelpPage = loadable(() => import('./pages/HelpPage'), {
    fallback: LoadingPageFallback,
  });

  return (
    <TokenSetter>
      <BrowserRouter>
        <NavBar />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path={links.scannerLink} element={<ScannerPage />} />
            <Route path={links.strategyCreatorLink} element={<StrategyCreatorPage />} />
            <Route path={links.smartBetaLink} element={<SamplePortfoliosPage />} />
            <Route path={links.helpLink} element={<HelpPage />} />
            <Route path="*" element={<NothingPage />} />
            
            <Route path={links.instrumentsLink} element={<InstrumentsPage/>} >
              <Route path=":instrumentId" element={<InstrumentsPage />} />
            </Route>

            <Route path={links.portfolioLink} element={<PortfolioPage />} >
              <Route path=":portfolioId" element={<PortfolioPage />} />
            </Route>

            <Route path={links.shareholderLink} element={<ShareholderPage />}>
              <Route path=":shareholderId" element={<ShareholderPage />} />
            </Route>

            <Route path={links.userPanelLink} element={<PrivateRoute roles={[process.env.REACT_APP_USER_ROLE!]}><UserPanelPage /></PrivateRoute>} >
              <Route index element={<PrivateRoute roles={[process.env.REACT_APP_USER_ROLE!]}><UserPortfoliosPage /></PrivateRoute>} />
              <Route path={links.userPanelPortfoliosLink} element={<PrivateRoute roles={[process.env.REACT_APP_USER_ROLE!]}><UserPortfoliosPage /></PrivateRoute>} />
              <Route path={links.userPanelReportsLink} element={<PrivateRoute roles={[process.env.REACT_APP_ADMIN_ROLE!]}><AdminUploadReportPage /></PrivateRoute>} />
              <Route path={links.userPanelInstrumentsLink} element={<PrivateRoute roles={[process.env.REACT_APP_ADMIN_ROLE!]}><AdminInstrumentsPage /></PrivateRoute>} />
              <Route path={links.userPanelStrategiesLink} element={<PrivateRoute roles={[process.env.REACT_APP_ADMIN_ROLE!]}><AdminStrategiesPage /></PrivateRoute>} />
            </Route>
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </TokenSetter>
  );
}

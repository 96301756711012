import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import styles from './NavBarModalItem.module.css';
import { userHasRole } from '../../../utils/PermissionChecker';

interface NavBarModalItemProps {
    label: string,
    link?: string,
    isAdminOnly?: boolean,
    action?: () => void
}

export default function NavBarModalItem({ link, action, label, isAdminOnly = false }: NavBarModalItemProps) {
    const { user } = useAuth();

    if (isAdminOnly) {
        if (!userHasRole(user?.access_token, process.env.REACT_APP_ADMIN_ROLE)) {
            return null;
        }
    }

    if (link) {
        return (
            <Link className={styles['navbar-modal-link']} to={link}>
                <span className={styles['navbar-modal-link-label']}>{label}</span>
            </Link>
        );
    }

    return (
        <div className={styles['navbar-modal-link']} onClick={action}>
            <span className={styles['navbar-modal-link-label']}>{label}</span>
        </div>
    );
}
import styles from './MenuIcon.module.css';

export default function StandardIcons() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={styles['navbar-embedded']}>
            <g id="navbar-icon-close">
                <path d="M47.949,50.625c-0.64,0-1.28-0.244-1.768-0.732L14.635,18.346c-0.977-0.976-0.977-2.559,0-3.535    c0.976-0.977,2.56-0.977,3.535,0l31.546,31.547c0.977,0.976,0.977,2.559,0,3.535C49.229,50.381,48.589,50.625,47.949,50.625z"></path>
                <path d="M16.051,50.273c-0.64,0-1.28-0.244-1.768-0.732c-0.977-0.976-0.977-2.559,0-3.535l31.898-31.898    c0.976-0.977,2.56-0.977,3.535,0c0.977,0.976,0.977,2.559,0,3.535L17.818,49.541C17.331,50.029,16.69,50.273,16.051,50.273z"></path>
            </g>
            <g id="navbar-icon-dropdown">
                <path d="M 31.973 47.916 L 31.973 47.916 c -0.873 0 -1.702 -0.38 -2.272 -1.041 l -18.78 -21.78 c -1.082 -1.255 -0.942 -3.149 0.313 -4.231 c 1.254 -1.083 3.149 -0.941 4.231 0.313 l 16.509 19.146 l 16.562 -19.198 c 1.081 -1.255 2.976 -1.396 4.231 -0.312 c 1.255 1.082 1.395 2.977 0.312 4.231 L 34.245 46.875 C 33.675 47.536 32.846 47.916 31.973 47.916 Z"></path>
            </g>
            <g id="navbar-icon-search">
                <path d="M21,21l-6-6 M17,10c0,3.9-3.1,7-7,7c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7C13.9,3,17,6.1,17,10z"></path>
            </g>
            <g id="icon_chevron_left">
                <path d="M40.863,53.807c-0.694,0-1.391-0.239-1.958-0.728l-21.78-18.781    c-0.661-0.57-1.041-1.399-1.041-2.271s0.38-1.702,1.041-2.272l21.832-18.833c1.256-1.083,3.149-0.942,4.231,0.312    c1.083,1.254,0.943,3.149-0.312,4.231L23.677,32.026l19.146,16.509c1.255,1.082,1.395,2.976,0.313,4.231    C42.543,53.454,41.706,53.807,40.863,53.807z"></path>
            </g>
            <g id="icon_chevron_right">
                <path d="M22.137,53.807c-0.843,0-1.68-0.353-2.273-1.041c-1.082-1.255-0.942-3.149,0.313-4.231    l19.146-16.509L20.125,15.464c-1.255-1.082-1.395-2.977-0.312-4.231c1.082-1.255,2.976-1.396,4.231-0.312l21.832,18.833    c0.661,0.57,1.041,1.399,1.041,2.272s-0.38,1.702-1.041,2.271l-21.78,18.781C23.528,53.568,22.831,53.807,22.137,53.807z"></path>
            </g>
        </svg>
    );
}
import { Link } from 'react-router-dom';
import Year from '../../components/dates/Year';
import styles from './Footer.module.css';
import links from '../../data/links.json';
import { useLocale } from '../../hooks/useLocale';

export default function Footer() {

    const { texts } = useLocale();

    return (
        <footer className={styles['investSharkFooter']}>
            <div className={styles['navbarFooter']}>
                <div className={styles['navbarFooter-logoContainer']}>
                    <Link to={links.homeLink} className={styles['navbarFooter-logo']}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.15 68.46" focusable={false}>
                            <use href='#navbar-icon-logo' />
                        </svg>
                    </Link>
                </div>
                <nav className={styles['navbarFooter-links']}>
                    <div className={styles['navbarFooter-link']}>
                        <span className={styles['navbarFooter-anchor']}>{texts.FOOTER_TEXT_1}</span>
                    </div>
                    <div className={styles['navbarFooter-link']}>
                        <span className={styles['navbarFooter-anchor']}>{texts.FOOTER_TEXT_2}</span>
                    </div>
                    <div className={styles['navbarFooter-link']}>
                        <span className={styles['navbarFooter-anchor']}>{texts.FOOTER_TEXT_3}</span>
                    </div>
                </nav>
                <div className={styles['navbarFooter-copyright']}>©<Year date={new Date()} />{` ${texts.FOOTER_NOTE}`}</div>
            </div>
        </footer>

    );
}
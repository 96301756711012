import styles from './NavBarMobileModalContainer.module.css';

interface NavBarDesktopModalContainerProps {
    isOpen: boolean,
    position: "left" | "right"
    children: React.ReactNode
}

export default function NavBarMobileModalContainer({ isOpen, children, position }: NavBarDesktopModalContainerProps) {

    const applyStyles = () => {
        if (!isOpen) {
            return undefined;
        }

        if (position === "right") {
            return { marginRight: '0px' };
        }

        if (position === "left") {
            return { marginLeft: '0px' };
        }

        return undefined;
    }

    const applyClasses = () => {
        if (position === "right") {
            return styles['navbar-accountModal'];
        }

        if (position === "left") {
            return styles['navbar-siteMenu'];
        }

        return null;
    }

    return (
        <div style={applyStyles()} className={[styles['navbar-modal'], applyClasses()].join(' ')}>
            <div className={styles['navbar-modalContent']}>
                {children}
            </div>
            <div className={styles['navbar-modalCloseGutter']}></div>
        </div>
    );
}
import { useAuth } from 'react-oidc-context';
import styles from './NavBarModalButton.module.css';
import { userHasRole } from '../../../utils/PermissionChecker';

interface NavBarModalButtonProps {
    label: string,
    isAdminOnly?: boolean,
    action: () => void
}

export default function NavBarModalButton({ action, label, isAdminOnly = false }: NavBarModalButtonProps) {
    const { user } = useAuth();

    if (isAdminOnly) {
        if (!userHasRole(user?.access_token, process.env.REACT_APP_ADMIN_ROLE)) {
            return null;
        }
    }

    return (
        <span className={[styles['navbar-button'], styles['is-full']].join(' ')} onClick={action}>{label}</span>
    );
}
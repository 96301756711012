import { useEffect, useState } from 'react';
import { axiosClient } from './lib/apiClient';
import { useAuth, hasAuthParams } from "react-oidc-context";
import { Loading } from './components/Loading';
import { PageLoaderContainer } from './layout/pageContainer/PageContainer';

interface TokenSetterProps {
    children: React.ReactNode
}

/**
 * Sets the token acquired from Keycloak in the Authorization header in Axios.
 */
export const TokenSetter = ({ children }: TokenSetterProps) => {
    const { activeNavigator, isAuthenticated, isLoading, events, user, signinSilent } = useAuth();
    const [initCallMade, setInitCallMade] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (!loggedIn && !hasAuthParams() &&
            !isAuthenticated && !activeNavigator && !isLoading) {
            signinSilent()
                .then((user) => {
                    setLoggedIn(true);
                    setInitCallMade(true);
                })
                .catch(() => {
                    setLoggedIn(false);
                    setInitCallMade(true);
                });
        }

        if (isAuthenticated) {
            setInitCallMade(true);
        }
    }, [loggedIn, isAuthenticated, activeNavigator, isLoading, signinSilent]);

    useEffect(() => {
        const getToken = () => {
            signinSilent()
                .then((user) => {
                    axiosClient.defaults.headers.common.Authorization = 'Bearer ' + user?.access_token;
                    console.log('Token was successfully refreshed');
                })
                .catch(() => {
                    axiosClient.defaults.headers.common.Authorization = null;
                    console.log('Failed to refresh the token, or the session has expired');
                });
        }

        events.addAccessTokenExpiring(getToken);

        return events.removeAccessTokenExpiring(getToken);

    }, [events, signinSilent]);

    if (isAuthenticated) {
        axiosClient.defaults.headers.common.Authorization = 'Bearer ' + user?.access_token;
    } else {
        axiosClient.defaults.headers.common.Authorization = null;
    }

    if (isLoading || !initCallMade) {
        return (
            <PageLoaderContainer>
                <Loading />
            </PageLoaderContainer>
        );
    }

    return children;
}
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';
import { userHasRole } from './utils/PermissionChecker';

interface PrivateRouteProps {
  children: React.ReactNode,
  roles: string[]
}

export function PrivateRoute({ children, roles }: PrivateRouteProps) {
  const { user } = useAuth();

  const isUserAuthorized = (roles: string[]) => {
    if (user && roles) {
      return roles.some(r => {
        return userHasRole(user?.access_token, r);
      });
    }
    return false;
  }

  return isUserAuthorized(roles)
    ? children
    : <Navigate to={"/"} />
}

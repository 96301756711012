import { NavLink, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import styles from './NavBarDesktop.module.css';
import links from '../../data/links.json';
import { useLocale } from '../../hooks/useLocale';
import { MenuType } from "./MenuType";

interface NavBarDesktopProps {
    openedMenu: MenuType,
    onAccountClick: () => void,
    onSearchClick: () => void
}

export default function NavBarDesktop({ openedMenu, onAccountClick, onSearchClick }: NavBarDesktopProps) {
    const { texts } = useLocale();
    const { isAuthenticated, user } = useAuth();

    const setDesktopNavClasses = (isActive: boolean) => {
        const defaultClasses = [styles['navbar-item'], styles['navbar-link']];

        if (isActive) {
            defaultClasses.push(styles['current']);
        }

        if (openedMenu === MenuType.DESKTOP_ACCOUNT_MENU || openedMenu === MenuType.DESKTOP_SEARCH_MENU) {
            defaultClasses.push(styles['is-faded']);
        }

        return defaultClasses.join(' ');
    }

    return (
        <div className={styles['navbar-desktop']}>
            <div className={[styles['navbar-desktopOverlay'], styles['navbar-overlay']].join(' ')}></div>
            <Link className={styles['navbar-logo']} to={links.homeLink}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.15 68.46" focusable={false}>
                    <use href='#navbar-icon-logo' />
                </svg>
            </Link>
            <div className={styles['navbar-items']}>
                <NavLink className={({ isActive }) => setDesktopNavClasses(isActive)} to={`/${links.strategyCreatorLink}`}>
                    <span className={styles['navbar-label']}>{texts.NAV_BAR_STRATEGY_CREATOR}</span>
                </NavLink>
                <NavLink className={({ isActive }) => setDesktopNavClasses(isActive)} to={`/${links.smartBetaLink}`}>
                    <span className={styles['navbar-label']}>{texts.NAV_BAR_SAMPLE_PORTFOLIOS}</span>
                </NavLink>
                <NavLink className={({ isActive }) => setDesktopNavClasses(isActive)} to={`/${links.scannerLink}`}>
                    <span className={styles['navbar-label']}>{texts.NAV_BAR_SCANNER}</span>
                </NavLink>
                <NavLink className={({ isActive }) => setDesktopNavClasses(isActive)} to={`/${links.helpLink}`}>
                    <span className={styles['navbar-label']}>{texts.NAV_BAR_HELP}</span>
                </NavLink>
            </div>
            <div className={styles['navbar-userProfile']}>
                <div className={openedMenu === MenuType.DESKTOP_SEARCH_MENU ? [styles['navbar-item'], styles['navbar-modalToggle'], styles['is-faded']].join(' ') : [styles['navbar-item'], styles['navbar-modalToggle']].join(' ')} onClick={onSearchClick}>
                    <div className={[styles['navbar-icon'], styles['navbar-searchIcon']].join(' ')}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable={false} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" role="presentation" fill="none" strokeWidth="2px" >
                            <use href='#navbar-icon-search' />
                        </svg>
                    </div>
                    <span className={styles['navbar-label']}>{texts.NAV_BAR_INSTRUMENTS}</span>
                </div>
                <div className={openedMenu === MenuType.DESKTOP_ACCOUNT_MENU ? [styles['navbar-item'], styles['navbar-modalToggle'], styles['is-active']].join(' ') : [styles['navbar-item'], styles['navbar-modalToggle']].join(' ')} onClick={onAccountClick}>
                    <span className={styles['navbar-label']}>{(isAuthenticated) ? user?.profile?.email : texts.ACCOUNT_MY_ACCOUNT}</span>
                    <div className={[styles['navbar-icon'], styles['navbar-dropdownIcon']].join(' ')}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" focusable={false}>
                            <use href='#navbar-icon-dropdown' />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}
import { Link, useNavigate } from 'react-router-dom';
import MobileModalClose from './MobileModalClose';
import NavBarMobileModalContainer from './NavBarMobileModalContainer';
import styles from './NavigationModalMobile.module.css';
import Select from 'react-select';
import selectStyles from '../../../styles/selectStyles';
import links from '../../../data/links.json';
import { useLocale } from '../../../hooks/useLocale';
import { useState } from 'react';

interface NavigationModalMobileProps {
    isOpen: boolean,
    instrumentsList: FinancialInstrumentSelect[],
    onCloseClick: () => void
}

export default function NavigationModalMobile({ isOpen, instrumentsList, onCloseClick }: NavigationModalMobileProps) {
    const { texts } = useLocale();
    const [selectedInstrument, setSelectedInstrument] = useState<unknown>();
    const navigate = useNavigate();

    const onSelectChange = (instrument: unknown) => {
        setSelectedInstrument(instrument);
        const financialInstrument = instrument as FinancialInstrumentSelect;
        navigate(`/${links.instrumentsLink}/${financialInstrument.value}`);
    }

    return (
        <NavBarMobileModalContainer isOpen={isOpen} position={'left'}>
            <Link to={links.homeLink} className={[styles['navbar-logo'], styles['navbar-mobileModalLogo']].join(' ')}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.15 68.46" focusable={false}>
                    <use href='#navbar-icon-logo' />
                </svg>
            </Link>
            <Link to={links.homeLink} className={styles['navbar-modalLink']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.NAV_BAR_HOME}</span>
            </Link>
            <Link to={`/${links.strategyCreatorLink}`} className={styles['navbar-modalLink']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.NAV_BAR_STRATEGY_CREATOR}</span>
            </Link>
            <Link to={`/${links.smartBetaLink}`} className={styles['navbar-modalLink']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.NAV_BAR_SAMPLE_PORTFOLIOS}</span>
            </Link>
            <Link to={`/${links.scannerLink}`} className={styles['navbar-modalLink']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.NAV_BAR_SCANNER}</span>
            </Link>
            <Link to={`/${links.helpLink}`} className={styles['navbar-modalLink']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.NAV_BAR_HELP}</span>
            </Link>
            <div className={styles['navbar-select']}>
                <span className={styles['navbar-modalLinkLabel']}>{texts.HOME_SEARCH_INSTRUMENT_HEADER}</span>
                <Select
                    styles={selectStyles}
                    placeholder={texts.HOME_SEARCH_INSTRUMENT_PLACEHOLDER}
                    value={selectedInstrument}
                    options={instrumentsList}
                    onChange={(instrument) => onSelectChange(instrument)} />
            </div>
            <MobileModalClose position={'left'} onCloseClick={onCloseClick} />
        </NavBarMobileModalContainer>
    );
}
import { useContext } from 'react';
import { LocaleContext } from '../context/LocaleContext';

export function useLocale() {
  var ctx = useContext(LocaleContext);
  if (!ctx) {
      throw new Error('useLocale hook must be used inside LocaleProvider context');
  }
  if (!ctx.code) {
      throw new Error('language has not been assigned to LocaleProvider');
  }

  if (!ctx.texts) {
    throw new Error('language file not found');
}

  var localeCode = ctx.texts.locale, localeTexts = ctx.texts;
  return { 
    code: localeCode,
    texts: localeTexts
};
}
import { useState, useEffect } from 'react';
import { PageSection } from '../../../components/pageSecion/PageSection';
import styles from './LynchRules.module.css';
import { useLocale } from '../../../hooks/useLocale';

export default function LynchRules() {

    const { texts } = useLocale();
    const [ruleText, setRuleText] = useState('');
    const [ruleSource, setRuleSource] = useState('');
    const lynchRules: any[] = texts.LYNCH_RULES;

    useEffect(() => {
        let randomIndex = Math.floor(Math.random() * lynchRules.length);
        if (ruleText === '') {
            setRuleText('"' + lynchRules[randomIndex].ruleText + '"');
            setRuleSource(lynchRules[randomIndex].ruleSource);
        }

        const interval = setInterval(() => {
            randomIndex = Math.floor(Math.random() * lynchRules.length);

            setRuleText('"' + lynchRules[randomIndex].ruleText + '"');
            setRuleSource(lynchRules[randomIndex].ruleSource);
        }, 10000);

        return () => {
            clearInterval(interval)
        };
    }, [ruleText, lynchRules]);

    return (
        <PageSection>
            <div className={styles['navbarFooter-rulesContainer']}>
                <div className={styles['widget-footer__rule-text']}>{ruleText}</div>
                <div className={styles['widget-footer__rule-source']}>{ruleSource}</div>
            </div>
        </PageSection>
    );
}
import { AppRouter } from './AppRouter';
import { AuthProvider } from "react-oidc-context";
import Offline from './components/offline/Offline';
import AppError from './AppError';
import { User } from 'oidc-client-ts';
import LocaleProvider from './context/LocaleContext';

export default function App() {

  const onSigninCallback = (_user: void | User) => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }

  const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY!,
    client_id: process.env.REACT_APP_CLIENT_ID!,
    redirect_uri: window.location.origin,
    post_logout_redirect_uri: window.location.origin,
    onSigninCallback: onSigninCallback,
    // ...
  };

  const locale = process.env.REACT_APP_LOCALE!;

  return (
    <AuthProvider {...oidcConfig}>
        <LocaleProvider languageCode={locale}>
          <AppError>
            <Offline>
              <AppRouter />
            </Offline>
          </AppError>
        </LocaleProvider>
    </AuthProvider>
  );
}

import styles from './NavBarDesktopModalContainer.module.css';

interface NavBarDesktopModalContainerProps {
    isOpen: boolean
    children: React.ReactNode
}

export default function NavBarDesktopModalContainer({ isOpen, children }: NavBarDesktopModalContainerProps) {
    return (
        <div className={styles['navbar-constrained']}>
            <div className={isOpen ? [styles['navbar-modal'], styles['navbar-dropdown'], styles['navbar-accountDropdown'], styles['is-open']].join(' ') : [styles['navbar-modal'], styles['navbar-dropdown'], styles['navbar-accountDropdown']].join(' ')}>
                <div className={styles['navbar-tick']}>
                    <div className={styles['navbar-tickInner']}></div>
                </div>
                <div className={styles['navbar-modalContent']}>
                    <div className={styles['navbar-accountDropdown']}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
import PortfolioImage from '../assets/pictograms/portfolio.svg';
import FormulaImage from '../assets/pictograms/formula.svg';
import InstrumentsImage from '../assets/pictograms/instruments.svg';
import MagnifierImage from '../assets/pictograms/magnifier.svg';
import ScannerImage from '../assets/pictograms/scanner.svg';
import CreatorImage from '../assets/pictograms/creator.svg';

export const localePL: Internalization = {
    "locale": "pl",
    "CURRENCY": "zł",
    "YEAR_UNIT": "lat",
    "WEEK": "tydzień",
    "MONTH": "miesiąc",
    "MONTHS_VARIANT_1": "miesiące",
    "MONTHS_VARIANT_2": "miesięcy",
    "YEAR": "rok",
    "YEARS_VARIANT_1": "lata",
    "YEARS_VARIANT_2": "lat",
    "SEE_MORE_TEXT": "Zobacz wszystkie",
    "CONFIRM_CHANGES": "Zatwierdź zmiany",

    "YES": "Tak",
    "NO": "Nie",
    "BACK": "Wstecz",
    "NEXT": "Dalej",
    "FINISH": "Zakończ",
    "SAVE": "Zapisz",
    "CANCEL": "Anuluj",
    "CLOSE": "Zamknij",
    "ALL": "Wszystkie",
    "NONE": "Brak",
    "TOTAL": "Ogółem",
    "EDIT": "Edytuj",

    "SELECT_DEFAULT_PLACEHOLDER": "--Wybierz wartość--",

    "OFFLINE_WARNING_1": "Jesteś offline",
    "OFFLINE_WARNING_2": "Sprawdź swoje połączenie z Internetem.",
    "EMPTY_PAGE_MESSAGE": "Nic tu nie ma...",
    "GENERIC_ERROR_MESSAGE": "Wystąpił problem z InvestShark",
    "LOADING_MESSAGE": "Trwa ładowanie...",
    "LOADING_MESSAGE_NOT_SET": "Nie ustawiono komunikatu.",
    "WAIT_MESSAGE": "Czekaj...",

    "NO_RESULTS_FOUND": "Nic nie znaleziono",

    "SEARCHING_NO_RESULTS": "Nie znaleziono wyników spełniajacych zadane kryteria.",
    "SEARCHING_MESSAGE": "Wyszukiwanie...",
    "SEARCHING_ERROR": "Coś poszło nie tak :-(",
    "SEARCHING_NUMBER_OF_RESULTS": "Znalezionych wyników",

    "ACCOUNT_CREATE_ACCOUNT": "Załóż konto",
    "ACCOUNT_LOG_IN": "Zaloguj się",
    "ACCOUNT_LOG_OUT": "Wyloguj się",
    "ACCOUNT_MY_ACCOUNT": "Moje konto",

    "NAV_BAR_HOME": "Strona główna",
    "NAV_BAR_SAMPLE_PORTFOLIOS": "Portfele",
    "NAV_BAR_INSTRUMENTS": "Instrumenty",
    "NAV_BAR_SCANNER": "Skaner",
    "NAV_BAR_STRATEGY_CREATOR": "Kreator Strategii",
    "NAV_BAR_HELP": "Pomoc",

    "NAV_USER_PORTFOLIOS": "Portfele",
    "NAV_USER_ADD_REPORT": "Dodaj raport finansowy",
    "NAV_USER_INSTRUMENTS": "Instrumenty",
    "NAV_USER_STRATEGIES": "Strategie",

    "FOOTER_TEXT_1": "Tymoteusz Trąpczyński",
    "FOOTER_TEXT_2": "tymon101@gmail.com",
    "FOOTER_TEXT_3": "NIP: 7831801539",
    "FOOTER_NOTE": "InvestShark, Wszystkie prawa zastrzeżone.",

    "ADMIN_PAGE_INSTRUMENTS_TITLE": "Instrumenty",
    "ADMIN_PAGE_STRATEGIES_TITLE": "Strategie",
    "ADMIN_PAGE_STRATEGIES_SUBTITLE": "Aktualizuj strategie dostępne w InvestShark",
    "ADMIN_PAGE_FINANCIAL_REPORTS_TITLE": "Dodaj raport finansowy",

    "USER_PAGE_TITLE": "Panel użytkownika",
    "USER_PAGE_PORTFOLIOS_SUBTITLE": "Zarządzaj swoimi portfelami",

    "HELP_PAGE_TITLE": "Pomoc",
    "HELP_PAGE_DESCRIPTION": "Nie wiesz co kryje się pod daną opcją? Nie masz pewności co oznacza dany parametr? Odpowiedź znajdziesz właśnie tutaj.",
    "HELP_TIMELINE_HEADER": "Data rozpoczęcia i okres rebalansu",
    "HELP_FILTERS_ORDER_HEADER": "Ustawienie filtrów",
    "HELP_MIRROR_IMAGE_HEADER": "Lustrzane odbicie",
    "HELP_MAX_POSITIONS_HEADER": "Maksymalna liczba spółek",
    "HELP_COMMISSION_HEADER": "Wysokość prowizji",
    "HELP_SLIDER_HEADER": "Suwak 0% - 100%",
    "HELP_FILTER_BUILD_HEADER": "Budowa filtru",

    "HOME_PAGE_TITLE": "InvestShark",
    "HOME_SEARCH_INSTRUMENT_HEADER": "Wyszukaj instrument",
    "HOME_SEARCH_INSTRUMENT_PLACEHOLDER": "Ticker lub nazwa",

    "SCANNER_PAGE_TITLE": "Skaner inwestycyjny",
    "SCANNER_PAGE_DESCRIPTION": "Przed Tobą narzędzie, które pozwoli Ci zaoszczędzić czas potrzebny na analizy. Wprowadź swoje kryteria, a InvestShark wyszuka wszystkie spółki, które je spełniają.",
    "SCANNER_LOADING_MESSAGE": "Trwa ładowanie skanera...",
    "SCANNER_LOADING_ERROR": "Wystąpił błąd",
    "SCANNER_GET_RESULTS": "Filtruj",

    "STRATEGY_CREATOR_PAGE_TITLE": "Kreator strategii",
    "STRATEGY_CREATOR_PAGE_SECTION_BUILDER": "Kreator",
    "STRATEGY_CREATOR_PAGE_SECTION_RESULTS": "Wyniki",
    "STRATEGY_CREATOR_PAGE_SECTION_PORTFOLIO": "Portfel",
    "STRATEGY_CREATOR_PORTFOLIO_NAME": "Wyniki strategii",

    "SAMPLE_PORTFOLIOS_PAGE_TITLE": "Przykładowe Portfele",
    "SAMPLE_PORTFOLIOS_PAGE_DESCRIPTION": "Poniżej znajduje się lista przykładowych portfeli inwestycyjnych utworzonych za pomocą InvestShark. Strategie użyte do stworzenia portfeli możesz podejrzeć w Kreatorze Strategii, a nawet wykorzystać je do stworzenia własnej. Pamiętaj, że o efektywności portfela nie decyduje wyłącznie uzyskana stopa zwrotu. Nigdy nie zapominaj o ryzyku.",

    "SHAREHOLDER_NOT_FOUND": "Nie znaleziono udziałowca lub nie posiada on udziałów",

    "home": {

        title: "InvestShark",
        subtitle: "Zaawansowany kreator strategii inwestycyjnych na polski rynek",

        features: [
            {
                id: 0,
                title: 'Kreator oparty o filtry',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                img: '/images/home/strategycreator-params-screenshot.png',
                imgDescription: 'Some description',
                linkTitle: 'Wybróbuj kreator',
                linkTo: '/strategyCreator',
                pictogram: CreatorImage
            },
            {
                id: 1,
                title: 'Bieżący podgląd dobieranych spółek',
                description: 'InvestShark przetwarza strategię okres po okresie, co oznacza, że masz bieżący wgląd w to, jakie spółki są dobierane do portfela. Każdy filtr jest przedstawiony osobno, co pozwala na obserwowanie, które spółki przeszły przez filtr oraz z jakimi wartościami.',
                img: '/images/home/strategycreator-results-screenshot.png',
                imgDescription: 'Some description',
                pictogram: MagnifierImage
            },
            {
                id: 2,
                title: 'Rozbudowane czynniki filtrujące',
                description: 'Przyzwyczajony do wybierania wskaźników z listy? A co jeśli danego wskaźnika tam nie ma? W InvestShark nie musisz się ograniczać do jednego wskaźnika jako czynnika filtrującego, ale możesz użyć całego wyrażenia metematycznego! Stwórz z czynników ranking, wybierz kierunek sortowania i wiele innych opcji.',
                img: '/images/home/portfolios-screenshot.png',
                imgDescription: 'Some description',
                pictogram: FormulaImage
            },
            {
                id: 3,
                title: 'Skaner Spółek',
                description: 'Czasem przydają się prostsze narzędzia, a skaner jest właśnie czymś takim. Wybierając 5 różnych kryteriów możesz wylistować całą paletę spółek ze wskazaną wartością. Wartości są posortowane i oznaczone kolorami.',
                img: '/images/home/scanner-screenshot.png',
                imgDescription: 'Some description',
                imgLocation: 'left',
                linkTitle: 'Skaner',
                linkTo: '/scanner',
                pictogram: ScannerImage
            },
            {
                id: 4,
                title: 'Skondensowane dane finansowe',
                description: 'InvestShark to nie tylko strategie. Jeśli szukasz danych finansowych o konkretnej spółce lub chcesz po prostu uzyskać więcej informacji na temat spółki dobranej przez strategie, to podstawowe informacje znajdziesz tutaj. Dane finansowe, akcjonariusze, dywidendy, notowania są do Twojej dyspozycji. Wybierz rok oraz kategorię, a ujrzysz wyniki za poszczególne kwartały i rok obrotowy. Dodatkowo od razu możesz porównać wartość z pozostałymi spółkami z tego samego sektora',
                img: '/images/home/instruments-screenshot.png',
                imgDescription: 'Some description',
                pictogram: InstrumentsImage
            },
            {
                id: 5,
                title: 'Wyniki jako portfel',
                description: 'Zobacz wyniki zbudowanej strategii w formie portfela inwestycyjnego. Porównaj ze sobą poszczególne okresy strategii, przeglądaj indywidualne spółki. Do Twojej dyspozycji są także miary efektywności takie jak wskaźniki Sharpe`a, Alpha i Beta.',
                linkTitle: 'Przyładowe portfele',
                linkTo: '/smartBeta',
                img: '/images/home/portfolios-screenshot.png',
                imgDescription: 'Wykres portfela oraz jego parametry efektywności',
                imgLocation: 'left',
                pictogram: PortfolioImage,
                list: [
                    'Twórz własne, wirtualne portfele składające się z akcji, gotówki i lokat',
                    'Wyniki strategii jako portfel inwestycyjny',
                    'Eksport zmian procentowych portfela i jego składu do pliku CSV',
                    'Nie wiesz w co zainwestować? Gotowe portfele czekają'
                ]
            }
        ]
    },

    "charts": {
        "labels": {
            "DATE": "Data",
            "VOLUME": "Wolumen",
            "PRICE": "Cena",
            "CASH": "Gotówka",
            "CHANGE": "Stopa zwrotu",
            "SHARE_PRICE": "Cena akcji",
            "INVESTOR": "Inwestor",
            "SHARE": "Udział",
            "INSTRUMENT": "Instrument",
            "PORTFOLIO": "Portfel",
            "VALUE": "Wartość",
            "VALUE_CHANGE": "Zmiana wartości"
        },
        "periods": {
            "ONE_MONTH_VALUE": "1m",
            "THREE_MONTHS_VALUE": "3m",
            "SIX_MONTHS_VALUE": "6m",
            "NINE_MONTHS_VALUE": "9m",
            "ONE_YEAR_VALUE": "1y",
            "TWO_YEARS_VALUE": "2y",
            "THREE_YEARS_VALUE": "3y",
            "FIVE_YEARS_VALUE": "5y",
            "TEN_YEARS_VALUE": "10y",
            "THIRTY_YEARS_VALUE": "30y"
        }
    },

    "financialReport": {
        "UPLOAD_NOTORIA_REPORT": "Wgraj raport (Notoria xlsx)",

        "CREATE_IF_NOT_EXISTS": "Stwórz jeśli nie istnieje",
        "GET_DIVIDENDS": "Pobierz dywidendy",
        "GET_HISTORICAL": "Pobierz dane historyczne",
        "PROCESS_REPORT": "Przetwarzaj raport",

        "TABLE_TICKER": "Ticker",
        "TABLE_TYPE": "Typ",
        "TABLE_STATUS": "Status",

        "NO_REPORTS_IN_PROGRESS": "Aktualnie nie jest przetwarzany żaden raport"
    },

    "instruments": {
        "NOT_FOUND": "Nie znaleziono instrumentów",

        "LATEST_TIMESTAMP": "Data najnowszego notowania",
        "GPW_COUNT": "Liczba spółek GPW",
        "NC_COUNT": "Liczba spółek NewConnect",
        "INSTRUMENTS_COUNT": "Liczba spółek",
        "QUOTES_TOTAL": "Liczba notowań",
        "LAST_QUOTE": "Ostatnie notowanie",
        "UPDATE_STOCK_DATA": "Aktualizuj dane historyczne",
    
        "UPDATE_QUOTES": "Aktualizuj notowania",
        "UPDATE_QUOTES_IN_PROGRESS": "Aktualizacja notowań w trakcie",
        "NO_DATA_FOUND": "Brak notowań dla w danym dniu.",
    
        "UPDATE_IN_PROGRESS": "Instrument jest właśnie uaktualniany",
        "UPDATING": "aktualizuję",
        "NEVER_UPDATED": "nigdy",
            
        "SEARCH_PLACEHOLDER_TEXT": "Wyszukiwarka",

        "PRICE_CHART_TITLE": "Cena",
        "VOLUME_CHART_TITLE": "Wolumen (obecna sesja",

        "financialData": {
            "SECTION_HEADER": "Dane finansowe",
            "YEAR_LABEL": "Rok",
            "CATEGORY_LABEL": "Kategorie",
            "TABLE_INDICATOR": "Pozycja",
            "TABLE_QUATERS": "Kwartalne",
            "TABLE_FIRST_QUATER": "I Q",
            "TABLE_SECOND_QUATER": "II Q",
            "TABLE_THIRD_QUATER": "III Q",
            "TABLE_FOURTH_QUATER": "IV Q",
            "DATA_TABLE_YEAR": "Roczny"
        },
        "investors": {
            "SECTION_HEADER": "Kluczowi inwestorzy",
            "INFO_HEADER": "Informacje",
            "TABLE_NAME": "Nazwa",
            "TABLE_DATE": "Data",
            "TABLE_NUMBER_OF_SHARES": "Liczba akcji",
            "TABLE_NOTES": "Uwagi",
            "TABLE_TOTAL_SHARE": "Udział",
            "NUMBER_OF_OPEN_PENSION_FUNDS": "Liczba inwestujących OFE",
            "OTHERS": "Pozostali"
        },
        "dividends": {
            "SECTION_HEADER": "Dywidendy",
            "SIDE_INFO_HEADER": "Analizy"
        },
        "instrument": {
            "ADD": "Dodaj instrument",
            "EDIT": "Edytuj instrument",
            "DELETE": "Usuń instrument",
            "CONFIRM_DELETE": "Czy na pewno chcesz usunąć instrument",
            "PEEK": "Podejrzyj instrument",

            "ID": "Id",
            "TICKER": "Ticker",
            "SHORT_NAME": "Skrócona nazwa",
            "NAME": "Nazwa",
            "WWW": "Strona internetowa",
            "DATETIME": "Data",
            "CHANGE": "Zmiana",
            "PREV_CLOSE": "Poprzednie zamknięcie",
            "OPEN": "Otwarcie",
            "LOW": "Najniższy",
            "HIGH": "Najwyższy",
            "CLOSE": "Zamknięcie",
            "VOLUME": "Wolumen",
            "TRADING_VOLUME": "Obrót",
            "BETA": "Beta",
            "DESCRIPTION": "Opis",
            "TYPE": "Typ",
            "MARKET": "Rynek",
            "SECTOR": "Sektor",
            "LAST_UPDATED": "Aktualizacja",
            "DATA_SIZE": "Rekordy",

            "SCANNER_RESULT_TITLE": "Przekrój sektora (w obrębie rynku)",
            "SCANNER_RESULT": "Średnia roczna wartość w sektorze"
        },
        "types": {
            "SHARES": "akcje",
            "INDEXES": "indeksy",
            "CONTRACTS": "kontrakty",
            "PORTFOLIOS": "portfele",
            "BONDS": "obligacje"
        }
    },

    "dividend": {
        "DIVIDEND_YIELD": "Stopa dywidendy",
        "DIVIDEND_PER_SHARE": "Dywidenda na akcje",
        "DIVIDEND_DAY": "Dzień wypłaty",
        "DIVIDEND_CONTINUITY": "Ciągłość dywidendy",
        "DIVIDEND_RAISING_CONTINUITY_PER_SHARE": "Ciągłość rosnącej dywidendy na akcję",
        "DIVIDEND_RAISING_CONTINUITY_YIELD": "Ciągłość rosnącej stopy dywidendy",
        "NUMBER_OF_DIVIDENDS": "Liczba dywidend",
        "LAST_DIVIDEND": "Ostatnia dywidenda",
        "UPDATING_MESSAGE": "Aktualizuj dane dywidendy"
    },

    "portfolios": {
        "FILE_COMPONENTS_SUFFIX": "_sklad.csv",
        "FILE_CHANGES_SUFFIX": "_zmiany_wartosci.csv",
        "NUMBER_OF_PORTFOLIOS": "Liczba portfeli",
        "LOWEST_VALUE": "Najmniejsza wartość",
        "HIGHEST_VALUE": "Największa wartość",
        "LOWEST_NUMBER_OF_INSTRUMENTS": "Najmniej spółek",
        "HIGHEST_NUMBER_OF_INSTRUMENTS": "Najwięcej spółek",
        "BEST": "Najlepszy",
        "WORST": "Najgorszy",
        "PIE_CHART_SHARE_OF_INTRUMENTS": "Udział spółek",
        "PIE_CHART_SHARE_OF_SECTORS": "Udział sektorów",
        "PIE_CHART_SHARE_OF_MARKETS": "Udział rynków",
        "CHART_ADD_PERIOD": "Wybierz okres:",
        "CHART_ADD_COMPONENT": "Dodaj na wykres:",
        "CHART_ADD_INDEX": "Porównaj z:",
        "SECURITIES_SECTION_TITLE": "Papiery wartościowe",
        "CASH_SECTION_TITLE": "Gotówka",
        "DEPOSITS_SECTION_TITLE": "Lokaty",
        "PERIOD": "Okres",
        "PERIOD_NUMBER_OF_INSTRUMENTS": "Liczba instrumentów:",
        "PORTFOLIOS_LIST_NAME": "Lista portfeli",
        "NOT_FOUND": "Brak portfeli inwestycyjnych",

        "portfolio": {
            "ADD": "Dodaj portfel",
            "EDIT": "Edytuj portfel",
            "DELETE": "Usuń portfel",
            "COPY": "Kopiuj portfel",

            "CREATE_HINT": "Kliknij na przycisk 'Dodaj portfel', aby utworzyć portfel",
            "DELETE_MESSAGE": "Czy na pewno chcesz usunąć ten portfel?",
            "NO_COMPONENTS_FOUND": "Brak pozycji w portfelu lub w okresie.",

            "NAME": "Nazwa portfela",
            "COPY_NAME": "Nazwa kopii portfela",
            "STATUS": "Status portfela",
            "STATUS_PUBLIC": "Publiczny",
            "STATUS_PRIVATE": "Prywatny",
            "SIZE": "Wielkość",
            "MIN_INVESTMENT": "Minimalny wkład",
            "REBALANCE_PERIOD": "Okres wymiany składu portfela",
            "RETURN_PERIOD": "Okres stopy zwrotu",
            "NUMBER_OF_POSITIONS": "Aktualna liczba pozycji",
            "NEXT_REBALANCE_DATE": "Data kolejnego rebalansu",
            "RETURN": "Stopa zwrotu",
            "TOTAL_RETURN": "Całkowita stopa zwrotu",
            "ALPHA_COEFFICIENT": "Współczynnik alfa",
            "BETA_COEFFICIENT": "Współczynnik beta",
            "SHARPE_RATIO": "Wskaźnik Sharpe'a",
            "DIVIDEND_SUM": "Suma dywidend",
            "COMMISSION_SUM": "Suma prowizji",
            "DAILY_STANDARD_DEVIATION": "Dzienne odchylenie standardowe",
            "WEEKLY_STANDARD_DEVIATION_PAST_5_YEARS": "Tygodniowe odchylenie standardowe (5 lat)",
            "LAST_CHANGE": "Ostatnia zmiana",
            "CURRENT_VALUE": "Obecna wartość",
            "RISK_LEVEL": "Poziom ryzyka",
            "RISK_LEVEL_1": "Bardzo niskie",
            "RISK_LEVEL_2": "Niskie",
            "RISK_LEVEL_3": "Umiarkowane niskie",
            "RISK_LEVEL_4": "Umiarkowane",
            "RISK_LEVEL_5": "Umiarkowane wysokie",
            "RISK_LEVEL_6": "Wysokie",
            "RISK_LEVEL_7": "Bardzo wysokie"
        },

        "portfolio_component": {
            "ADD": "Dodaj instrument",
            "EDIT": "Edytuj instrument",
            "DELETE": "Usuń instrument",
            "BUY": "Kup instrument",
            "SELL": "Sprzedaj instrument",

            "DELETE_MESSAGE": "Czy na pewno chcesz usunąć z portfela instrument",
            "NO_COMPONENTS_MESSAGE": "Brak akcji w portfelu",

            "BUYING_DATE": "Data zakupu",
            "BUYING_PRICE": "Cena nabycia",
            "BUYING_VALUE": "Wartość kupna",
            "SELLING_DATE": "Data sprzedaży",
            "SELLING_PRICE": "Cena sprzedaży",
            "SELLING_VALUE": "Wartość sprzedaży",
            "CURRENT_PRICE": "Obecna cena",
            "CURRENT_VALUE": "Obecna wartość",
            "COMMISSION": "Prowizja",
            "NUMBER_OF_SHARES": "Liczba akcji",
            "POSITION": "Pozycja",
            "POSITION_LONG": "Długa",
            "POSITION_SHORT": "Krótka",
            "DIVIDEND": "Dywidenda",
            "BALANCE": "Bilans"
        },

        "portfolio_cash": {
            "ADD": "Dodaj gotówkę",
            "EDIT": "Edytuj gotówkę",
            "DELETE": "Usuń gotówkę",

            "DEPOSITED_WITHDRAWN_AMOUNT": "Wpłacona/wypłacona kwota",
            "DATE": "Data",
            "CHANGE": "Zmiana gotówki",
            "DESCRIPTION": "Opis",
            "INCOME": "Przychód",

            "BUY_WITH_CASH_MESSAGE": "Finansuj z gotówki",
            "MOVE_TO_CASH": "Przenieś do gotówki",
            "DO_NOT_MOVE_TO_CASH": "Nic nie rób",
            "NO_CASH_MESSAGE": "Brak gotówki w portfelu.",
            "DELETE_MESSAGE": "Czy na pewno chcesz usunąć pozycję gotówki z danego dnia z portfela ?"
        },

        "portfolio_deposit": {
            "ADD": "Dodaj lokatę",
            "EDIT": "Edycja lokaty",
            "DELETE": "Usuń lokatę",
            "TERMINATE": "Zakończ lokatę",

            "DELETE_MESSAGE": "Czy na pewno chcesz usunąć następującą lokatę z portfela?",
            "NO_DEPOSITS_MESSAGE": "Brak lokat oszczędnościowych w portfelu.",

            "NAME": "Nazwa lokaty",
            "INTEREST_RATE": "Oprocentowanie",
            "DURATION": "Czas trwania lokaty w miesiącach",
            "CAPITALIZATION_PERIOD": "Okres kapitalizacji w miesiącach",
            "TERMINATION_DATE": "Data likwidacji lokaty",
            "OPEN_DATE": "Data otwarcia lokaty",
            "EXPIRE_DATE": "Data wygaśnięcia lokaty",
            "INVESTED_VALUE": "Ulokowana kwota",
            "CURRENT_VALUE": "Obecna wartość"
        }
    },

    "strategies": {
        "strategy": {
            "SAVE": "Zapisz strategię",
            "UPDATE": "Aktualizuj",
            "EDIT": "Edytuj parametry",
            "DELETE": "Usuń strategię",

            "DELETE_MESSAGE": "Czy na pewno chcesz usunąć strategię",

            "ID": "id",
            "NAME": "Nazwa strategii",
            "BEGINING_DATE": "Data rozpoczęcia",
            "REBALANCE_PERIOD": "Okres rebalansu portfela (w miesiącach)",
            "COMMISSION_VALUE": "Wysokość prowizji (w %)",
            "STANDARD": "Standard",
            "MIRROR_IMAGE": "Lustrzane odbicie",
            "MAX_POSITIONS": "Maksymalna liczba spółek",
            "MAX_QUOTE_AGE": "Wiek notowania (w dniach)",
            "LAST_UPDATED": "Ostatnia aktualizacja",
            "ADDITIONAL_SETTINGS": "Opcje strategii",
            "STATUS": "Status",
            "FILTERS_ORDER": "Ustawienie filtrów",
            "FILTERS_ORDER_SEQUENTIONAL": "Sekwencyjne",
            "FILTERS_ORDER_PARALLEL": "Równolegle",
            "CHOOSE": "Wybierz strategię",
            "NUMBER_OF_COMPANIES": "Liczba spółek",
            "PERIODIC_RESULTS": "Wyniki okresowe",
            "NO_PERIODIC_RESULTS": "Wyniki będą dostępne po zakończeniu przetwarzania pierwszego okresu",
            "PROCESSING_TIME": "Czas przetwarzania",
            "INSTRUMENTS": "Instrumenty",
            "FINAL_NUMBER_OF_INSTRUMENTS": "Końcowa lista spółek i ich procentowy udział",
            
            "USER_STRATEGIES": "Własne strategie",
            "USER_STRATEGIES_LOGIN_MESSAGE": "Zaloguj się aby zapisywać i wczytywać własne strategie",
            "USER_STRATEGIES_NOT_FOUND": "Brak strategii",

            "ALREADY_EXISTS_MESSAGE": "Strategia o podanej nazwie już istnieje. Zapisanie spowoduje jej zastąpienie.",
            "NUMBER_OF_STRATEGIES": "Liczba dostępnych strategii",

            "ERROR_INVALID_FILTERS": "Co najmniej jeden filtr zawiera błędy"
        },
        "strategyLoader": {
            "TABLE_HEADER_PERIOD": "Okres",
            "TABLE_HEADER_POSITIONS": "Liczba spółek",
            "TABLE_HEADER_ELAPSED_TIME": "Czas wykonywania",

            "START_LOADING": "Rozpocznij przetwarzanie",
            "STOP_LOADING": "Przerwij",
            "ESTIMATED_REMAINING_TIME": "Pozostało około",
            "IN_PROGRESS": "W trakcie...",
            "PREPARE_TO_CANCEL_MESSAGE": "Zaczekaj...",

            "REQUEST_ID": "Request ID"
        },
        "filter": {
            "ADD": "Dodaj filtr",

            "TYPE": "Typ",
            "INDICATOR_TYPE": "Wskaźnik",
            "OPERATOR_TYPE": "Operator",
            "NUMBER_TYPE": "Liczba",
            "UNKNOWN_TYPE": "Nieznany",

            "FILTER_LABEL": "Filtr",
            "FILTERS_LABEL": "Filtry",
            "MARKETS_LABEL": "Rynki",
            "SECTORS_LABEL": "Sektory",
            "YEAR_LABEL": "Rok",
            "QUATER_LABEL": "Kwartał",
            "FILTERING_FACTOR_LABEL": "Czynnik filtrujący",
            "EMPTY_FILTER": "Brak",
            "RANGE": "Zakres",

            "SORT_LABEL": "Sortuj wartości",
            "SORT_DESCENDING": "Malejąco",
            "SORT_ASCENDING": "Rosnąco",
            "MAX_POSITIONS_LABEL": "Maksymalna liczba pozycji",

            "FILTERS_CLEAR": "Wyczyść filtry",
            "FILTERS_CANNOT_BE_EMPTY": "Filtry nie mogą być puste",
            "FILTERS_DRAG_AND_DROP_HINT": "Wskazówka: Filtry możesz dowolnie zamieniać ze sobą miejscami przeciągając je za etykietę.",

            "FACTOR": "Czynnik",
            "ADD_FACTOR": "Dodaj czynnik",
            "DELETE_FACTOR": "Usuń czynnik",
            "REQUIRE_ALL_FACTORS_LABEL": "Wymagaj wszystkich czynników",

            "RANKING": "Ranking",

            "ERROR_CHANGING_RANKING_TO_SINGLE": "Zamiana na zwykły filtr jest możliwa tylko przy pozostawieniu jednego czynnika",
            "ERROR_EVENT_TYPE": "Nieprawidłowy typ zdarzenia",
            "ERROR_PARENTHESES_MISSING": "Liczba otwartych i zamkniętych nawiasów musi być taka sama",
            "ERROR_INDICATORS_NEXT_TO_EACH_OTHER": "Wskaźniki muszą być rozdzielone operatorem matematycznym",
            "ERROR_OPERATORS_TO_CLOSE": "Operatory matematyczne muszą być rozdzielone liczbą lub wskaźnikiem",
            "ERROR_NUMBERS_NEXT_TO_EACH_OTHER": "Liczby nie mogą znajdować się bezpośrednio obok siebie",
            "ERROR_NUMBER_AND_INDICATOR_NEXT_TO_EACH_OTHER": "Liczba nie może znajdować się bezpośrednio obok wskaźnika",
            "ERROR_PERIOD_WITHOUT_INDICATOR": "Argument okresu np. [1y] może występować jedynie po wskaźniku",
            "ERROR_PERIOD_WRONG_FORMAT": "Jeden lub więcej agrumentów okresu ma niewłaściwy format. Właściwy format: [1y]",
            "ERROR_MATH_OPERATOR_AT_THE_BEGINING": "Operator matematyczny nie może znajdować się na początku",
            "ERROR_MATH_OPERATOR_AT_THE_END": "Operator matematyczny nie może znajdować się na końcu",
            "ERROR_NO_INDICATORS": "Wyrażenie musi zawierać co najmniej jeden wskaźnik",
            "ERROR_INVALID_ELEMENTS": "Wykryto niedozwolone elementy"
        }
    },

    "LYNCH_RULES": [
        {"id": 1, "ruleText": "Dżentelmeni inwestujący w obligacje nie wiedzą, co tracą.", "ruleSource": "II zasada Petera Lyncha"},
        {"id": 2, "ruleText": "Nie inwestuj w żaden pomysł, którego nie możesz zilustrować ołówkiem.", "ruleSource": "III zasada Petera Lyncha"},
        {"id": 3, "ruleText": "Przez lusterko wsteczne nie dojrzysz przyszłości.", "ruleSource": "IV zasada Petera Lyncha"},
        {"id": 4, "ruleText": "Nie ma sensu płacić Yo-Yo Ma, żeby grało radio.", "ruleSource": "V zasada Petera Lyncha"},
        {"id": 5, "ruleText": "Skoro musisz wybierać fundusz, równie dobrze możesz wybierać akcje.", "ruleSource": "VI zasada Petera Lyncha"},
        {"id": 6, "ruleText": "Im bardziej ekstrawaganckie siedziby, tym mniejsza ochota na sowite wynagradzanie akcjonariuszy.", "ruleSource": "VII zasada Petera Lyncha"},
        {"id": 7, "ruleText": "Jeśli długoterminowe obligacje rządowe przewyższają dywidendy z akcji indeksu S&P 500 o co najmniej 6 procent, sprzedaj akcje i kup obligacje.", "ruleSource": "VIII zasada Petera Lyncha"},
        {"id": 8, "ruleText": "Nie wszystkie popularne akcje są popularne jednakowo.", "ruleSource": "IX zasada Petera Lyncha"},
        {"id": 9, "ruleText": "Jadąc niemiecką autostradą, nigdy nie patrz w lusterko wsteczne.", "ruleSource": "X zasada Petera Lyncha"},
        {"id": 10, "ruleText": "Bardzo możliwe, że najlepsze akcje do kupienia to te, które już posiadasz.", "ruleSource": "XI zasada Petera Lyncha"},
        {"id": 11, "ruleText": "Lekarstwem na traktowanie akcji jako czegoś oczywistego, jest ostry spadek ich cen.", "ruleSource": "XII zasada Petera Lyncha"},
        {"id": 12, "ruleText": "Nie licz na powrót, gdy grają na pożegnanie.", "ruleSource": "XIII zasada Petera Lyncha"},
        {"id": 13, "ruleText": "Jeśli podoba ci się sklep, to są szanse, że pokochasz akcje.", "ruleSource": "XIV zasada Petera Lyncha"},
        {"id": 14, "ruleText": "Jeśli kupują insiderzy, jest to dobry znak - chyba, że dzieje się to w banku z Nowej Anglii.", "ruleSource": "XV zasada Petera Lyncha"},
        {"id": 15, "ruleText": "W biznesie konkurencja nigdy nie jest tak korzystna, jak całkowita dominacja.", "ruleSource": "XVI zasada Petera Lyncha"},
        {"id": 16, "ruleText": "Jeśli dwie firmy niczym się nie różnią, inwestuj w tę, która ma w prospekcie mniej kolorowych obrazków.", "ruleSource": "XVII zasada Petera Lyncha"},
        {"id": 17, "ruleText": "Jeśli jakimiś akcjami znudzili się nawet analitycy, to czas zacząć je kupować.", "ruleSource": "XVIII zasada Petera Lyncha"},
        {"id": 18, "ruleText": "Jeśli nie nastawiasz się na błyskawiczne zyski, ani nie jesteś poetką szukającą bogatego męża, to pesymizm się nie opłaca.", "ruleSource": "XIX zasada Petera Lyncha"},
        {"id": 19, "ruleText": "Przedsiębiorstwa, jak ludzie, zmieniają nazwiska albo po ślubie, albo po jakiejś porażce, którą chcą jak najszybciej puścić w zapomnienie.", "ruleSource": "XX zasada Petera Lyncha"},
        {"id": 20, "ruleText": "Jak królowa sprzedaje, to kupuj.", "ruleSource": "XXI zasada Petera Lyncha"}
    ],

    "FINANCIAL_LABELS": [
        {
            "label": "Aktywa",
            "options": [
                {"value": "A_0", "label": "Aktywa"},
                {"value": "AA_1", "label": "Aktywa trwałe"},
                {"value": "AA_2", "label": "Aktywa obrotowe"},
                {"value": "A_1", "label": "Kasa i operacje z bankiem centralnym"},
                {"value": "A_2", "label": "Należności od banków"},
                {"value": "A_3", "label": "Należności od klientów"},
                {"value": "A_4", "label": "Należności ubezpieczeniowe"},
                {"value": "A_5", "label": "Aktywa reasekuracyjne"},
                {"value": "A_6", "label": "Regresy i odzyski"},
                {"value": "A_7", "label": "Aktywa z tytułu odroczonego podatku dochodowego"},
                {"value": "A_8", "label": "Instrumenty pochodne"},
                {"value": "A_9", "label": "Długoterminowe instrumenty pochodne"},
                {"value": "A_10", "label": "Aktywa finansowe wyceniane w wartości godziwej przez wynik finansowy"},
                {"value": "A_11", "label": "Aktywa finansowe utrzymywane do terminu wymagalności"},
                {"value": "A_12", "label": "Aktywa finansowe dostępne do sprzedaży"},
                {"value": "A_13", "label": "Inwestycje w jednostki powiązane"},
                {"value": "A_14", "label": "Rzeczowe aktywa trwałe"},
                {"value": "A_15", "label": "Nieruchomości inwestycyjne"},
                {"value": "A_16", "label": "Wartości niematerialne i prawne"},
                {"value": "A_17", "label": "Wartość firmy"},
                {"value": "A_18", "label": "Aktywa z tytułu odroczonego podatku dochodowego"},
                {"value": "A_19", "label": "Aktywa z tytułu bieżącego podatku"},
                {"value": "A_20", "label": "Aktywa niematerialne"},
                {"value": "A_21", "label": "Aktywa finansowe"},
                {"value": "A_22", "label": "Długoterminowe aktywa finansowe"},
                {"value": "A_23", "label": "Zapasy"},
                {"value": "A_24", "label": "Należności handlowe"},
                {"value": "A_25", "label": "Długoterminowe pożyczki i należności"},
                {"value": "A_26", "label": "Rozliczenia międzyokresowe [Aktywa]"},
                {"value": "A_27", "label": "Długoterminowe rozliczenia międzyokresowe"},
                {"value": "A_28", "label": "Środki pieniężne i ich ekwiwalenty"},
                {"value": "A_29", "label": "Pozostałe aktywa"},
                {"value": "A_30", "label": "Pozostałe aktywa długoterminowe"},
                {"value": "A_31", "label": "Aktywa przeznaczone do zbycia i działalność zaniechana"},
                {"value": "A_32", "label": "Aktywa biologiczne"},
                {"value": "A_33", "label": "Pożyczki i pozostałe należności"},
                {"value": "A_34", "label": "Aktywa z tytułu poszukiwania i oceny zasobów mineralnych"},
                {"value": "A_35", "label": "Aktywa finansowe przeznaczone do obrotu"},
                {"value": "A_39", "label": "Akcje własne"},
                {"value": "A_40", "label": "Należne wpłaty na kapitał"}
            ]
        },
        {
            "label": "Pasywa",
            "options": [
                {"value": "P_0", "label": "Pasywa"},
                {"value": "PP_1", "label": "Kapitał własny udziałowców podmiotu dominującego"},
                {"value": "PP_2", "label": "Zobowiązania długoterminowe"},
                {"value": "PP_3", "label": "Zobowiązania krótkoterminowe"},
                {"value": "PP_4", "label": "Zobowiązania"},
                {"value": "P_1", "label": "Kapitał podstawowy"},
                {"value": "P_2", "label": "Należne wpłaty na kapitał podstawowy"},
                {"value": "P_3", "label": "Akcje/udziały własne"},
                {"value": "P_4", "label": "Kapitał zapasowy"},
                {"value": "P_5", "label": "Wyceny i różnice kursowe"},
                {"value": "P_6", "label": "Pozostałe kapitały"},
                {"value": "P_7", "label": "Zyski zatrzymane / niepokryte straty"},
                {"value": "P_8", "label": "Udziały niekontrolujące"},
                {"value": "P_9", "label": "Zobowiązania wobec banku centralnego"},
                {"value": "P_10", "label": "Zobowiązania z tytułu instrumentów pochodnych"},
                {"value": "P_11", "label": "Długoterminowe zobowiązania z tytułu instrumentów pochodnych"},
                {"value": "P_12", "label": "Zobowiązania wobec banków"},
                {"value": "P_13", "label": "Zobowiązania wobec klientów"},
                {"value": "P_14", "label": "Zobowiązania finansowe wyceniane do wartości godziwej przez wynik finansowy"},
                {"value": "P_15", "label": "Zobowiązania finansowe przeznaczone do obrotu"},
                {"value": "P_16", "label": "Zobowiązania z tytułu emisji dłużnych papierów wartościowych"},
                {"value": "P_17", "label": "Zobowiązania podporządkowane"},
                {"value": "P_18", "label": "Rezerwy"},
                {"value": "P_19", "label": "Długoterminowe rezerwy"},
                {"value": "P_20", "label": "Rezerwy techniczno-ubezpieczeniowe"},
                {"value": "P_22", "label": "Rezerwa z tytułu odroczonego podatku dochodowego"},
                {"value": "P_23", "label": "Zobowiązania z tytułu bieżącego podatku"},
                {"value": "P_24", "label": "Pozostałe zobowiązania"},
                {"value": "P_25", "label": "Pozostałe zobowiązania długoterminowe"},
                {"value": "P_26", "label": "Świadczenia pracownicze"},
                {"value": "P_27", "label": "Długoterminowe rezerwy na świadczenia pracownicze"},
                {"value": "P_28", "label": "Zobowiązania finansowe [kredyty i pożyczki]"},
                {"value": "P_29", "label": "Długoterminowe kredyty i pożyczki"},
                {"value": "P_30", "label": "Rozliczenia międzyokresowe [zobowiązania]"},
                {"value": "P_31", "label": "Długoterminowe rozliczenia międzyokresowe [zobowiązania]"},
                {"value": "P_32", "label": "Zobowiązania handlowe"},
                {"value": "P_33", "label": "Długoterminowe zobowiązania handlowe"},
                {"value": "P_34", "label": "Zobowiązania związane z aktywami do zbycia i działalnością zaniechaną"},
                {"value": "P_35", "label": "Zobowiązania finansowe wyceniane w zamortyzowanym koszcie"},
                {"value": "P_40", "label": "Zobowiązania z tytułu obligacji"},
                {"value": "P_41", "label": "Długoterminowe zobowiązania z tytułu obligacji"},
                {"value": "P_42", "label": "Zobowiązania z tytułu leasingu"},
                {"value": "P_43", "label": "Długoterminowe zobowiązania z tytułu leasingu"},
                {"value": "xP_0", "label": "Współczynnik wypłacalności"}
            ]
        },
        {
            "label": "Przychody",
            "options": [
                {"value": "WO_1", "label": "Pozostałe przychody operacyjne"},
                {"value": "WO_2", "label": "Pozostałe koszty operacyjne"},
                {"value": "WO_3", "label": "Wynik na aktywach niefinansowych"},
                {"value": "WO_4", "label": "Odpisy z tytułu utraty wartości"},
                {"value": "WO_6", "label": "Rozwiązanie odpisów z tytułu utraty wartości"},
                {"value": "WO_0", "label": "Zysk/strata z działalności operacyjnej"},
                {"value": "WB_1", "label": "Przychody finansowe"},
                {"value": "WB_2", "label": "Koszty finansowe"},
                {"value": "WB_3", "label": "Różnice kursowe"},
                {"value": "WB_4", "label": "Wynik na inwestycjach"},
                {"value": "WB_5", "label": "Wynik zdarzeń nadzwyczajnych"},
                {"value": "WB_6", "label": "Udział w wyniku jednostek stowarzyszonych i wspólnych przedsięwzięć"},
                {"value": "WB_7", "label": "Podatek od instytucji finansowych"},
                {"value": "WB_0", "label": "Zysk/strata brutto"},
                {"value": "WN_1", "label": "Podatek dochodowy"},
                {"value": "WN_2", "label": "Pozostałe zdarzenia i odpisy z zysku"},
                {"value": "WN_3", "label": "Udział w wyniku jednostek powiązanych"},
                {"value": "WN_4", "label": "Zysk/strata netto na działalności zaniechanej"},
                {"value": "WN_0", "label": "Zysk/strata netto"},
                {"value": "xWN_1", "label": "Zysk/strata netto udziałowców jednostki dominującej"},
                {"value": "xWN_2", "label": "Zysk/strata netto właścicieli udziałów niekontrolujących"},
                {"value": "CC_0", "label": "Pozostałe całkowite dochody, netto"},
                {"value": "CC_1", "label": "Różnice kursowe z jednostek powiązanych"},
                {"value": "CC_2", "label": "Wynik z aktualizacji wycen"},
                {"value": "CC_3", "label": "Zabezpieczenie przepływów pieniężnych"},
                {"value": "CC_4", "label": "Inne składniki pozostałego całkowitego dochodu"},
                {"value": "CC_5", "label": "Podatek odroczony od pozostałych całkowitych dochodów"},
                {"value": "DD_0", "label": "Całkowite dochody ogółem"},
                {"value": "xDD_1", "label": "Całkowite dochody udziałowców jednostki dominującej"},
                {"value": "xDD_2", "label": "Całkowite dochody udziałowców niekontrolujących"},
                {"value": "AM_0", "label": "Amortyzacja [noty]"},
                {"value": "xWB_2", "label": "Naliczone odsetki"},
                {"value": "PD_0", "label": "Przychody ze sprzedaży"},
                {"value": "PPD_0", "label": "Zysk/strata ze sprzedaży"},
                {"value": "PD_1", "label": "Przychody ze sprzedaży produktów i usług"},
                {"value": "PD_2", "label": "Przychody ze sprzedaży towarów i materiałów"},
                {"value": "PD_3", "label": "Pozostałe przychody z działalności podstawowej"},
                {"value": "PPD_2", "label": "Zysk/strata brutto ze sprzedaży"},
                {"value": "PD_4", "label": "Przychody z tytułu odsetek"},
                {"value": "PPD_4", "label": "Wynik z tytułu odsetek"},
                {"value": "PD_5", "label": "Przychody z tytułu opłat i prowizji"},
                {"value": "PPD_5", "label": "Wynik z tytułu opłat i prowizji"},
                {"value": "PD_6", "label": "Składki przypisane, brutto"},
                {"value": "PPD_6", "label": "Składki przypisane, netto"},
                {"value": "PD_7", "label": "Udział reasekuratorów w składce ubezpieczeniowej przypisanej, brutto"},
                {"value": "PD_8", "label": "Zmiana stanu rezerw składki ubezpieczeniowej"},
                {"value": "PD_10", "label": "Przychody z tytułu dywidend"},
                {"value": "PD_13", "label": "Przychody netto z inwestycji"},
                {"value": "PD_14", "label": "Wynik na instrumentach finansowych"},
                {"value": "PD_17", "label": "Wynik z pozycji wymiany"},
                {"value": "PD_18", "label": "Wynik z tytułu rachunkowości zabezpieczeń"},
                {"value": "PD_21", "label": "Aktualizacja wartości inwestycji"},
                {"value": "KD_0", "label": "Koszty podstawowej działalności operacyjnej"},
                {"value": "KKD_1", "label": "Koszt własny sprzedaży"},
                {"value": "KD_1", "label": "Koszt sprzedanych produktów i usług"},
                {"value": "KD_2", "label": "Koszt sprzedanych towarów i materiałów"},
                {"value": "KD_3", "label": "Koszty sprzedaży"},
                {"value": "KD_4", "label": "Koszty ogólnego zarządu"},
                {"value": "KD_5", "label": "Koszty działalności operacyjnej [porównawczy]"},
                {"value": "xKD_5", "label": "Koszty według rodzaju"},
                {"value": "xKD_5_1", "label": "Amortyzacja [koszt]"},
                {"value": "xKD_5_2", "label": "Zużycie materiałów i energii"},
                {"value": "xKD_5_3", "label": "Usługi obce"},
                {"value": "xKD_5_4", "label": "Podatki i opłaty"},
                {"value": "xKD_5_5", "label": "Wynagrodzenia"},
                {"value": "xKD_5_6", "label": "Ubezpieczenia społeczne i inne"},
                {"value": "xKD_5_7", "label": "Pozostałe koszty rodzajowe"},
                {"value": "xKD_5_8", "label": "Wartość sprzedanych towarów i materiałów"},
                {"value": "KD_6", "label": "Koszty z tytułu odsetek"},
                {"value": "KD_7", "label": "Koszty z tytułu opłat i prowizji"},
                {"value": "KKD_8", "label": "Wypłacone odszkodowania i świadczenia, netto"},
                {"value": "KD_8", "label": "Wypłacone odszkodowania i świadczenia, brutto"},
                {"value": "KD_9", "label": "Reinsurers share of claims and benefits paid"},
                {"value": "KD_10", "label": "Zmiana stanu rezerw techniczno-ubezpieczeniowych"},
                {"value": "KD_12", "label": "Aktualizacja wartości zobowiązań finansowych"},
                {"value": "KD_13", "label": "Koszty akwizycji"},
                {"value": "KD_14", "label": "Koszty działania [instytucje finansowe]"}
            ]
        },
        {
            "label": "Przepływy pieniężne",
            "options": [
                {"value": "CFA_0", "label": "Przepływy operacyjne"},
                {"value": "CFA_1", "label": "Wynik finansowy"},
                {"value": "CFA_2", "label": "Udział w wyniku jednostek powiązanych"},
                {"value": "CFA_3", "label": "Amortyzacja [przepływy operacyjne]"},
                {"value": "CFA_4", "label": "Różnice kursowe z działalności operacyjnej"},
                {"value": "CFA_5", "label": "Odsetki i dywidendy z działalności operacyjnej"},
                {"value": "CFA_6", "label": "Wynik na działalności inwestycyjnej"},
                {"value": "CFA_7", "label": "Podatek dochodowy zapłacony"},
                {"value": "CFA_8", "label": "Płatności rozliczane w akcjach"},
                {"value": "CFA_9", "label": "Zmiana stanu rezerw"},
                {"value": "CFA_10", "label": "Zmiana stanu zapasów"},
                {"value": "CFA_11", "label": "Zmiana stanu należności"},
                {"value": "CFA_12", "label": "Zmiana stanu zobowiązań"},
                {"value": "CFA_13", "label": "Zmiana stanu rozliczeń międzyokresowych"},
                {"value": "CFA_14", "label": "Zmiana stanu pozostałych aktywów i pasywów"},
                {"value": "CFA_16", "label": "Pozostałe przepływy operacyjne"},
                {"value": "CFA_17", "label": "Zmiana stanu należności od banków"},
                {"value": "CFA_18", "label": "Zmiana stanu należności od klientów"},
                {"value": "CFA_19", "label": "Zmiana stanu aktywów finansowych"},
                {"value": "CFA_20", "label": "Zmiana stanu instrumentów pochodnych"},
                {"value": "CFA_21", "label": "Zmiana stanu zobowiązań wobec banków"},
                {"value": "CFA_22", "label": "Zmiana stanu zobowiązań wobec klientów"},
                {"value": "CFA_23", "label": "Zmiana stanu zobowiązań finansowych"},
                {"value": "CFA_28", "label": "Zmiana stanu należności ubezpieczeniowych"},
                {"value": "CFA_29", "label": "Zmiana stanu zobowiązań ubezpieczeniowych"},
                {"value": "CFA_30", "label": "Wynik na zmianie wartości aktywów"},
                {"value": "CFA_31", "label": "Podatek dochodowy odroczony"},
                {"value": "CFA_40", "label": "Przepływy operacyjne z działalności zaniechanej"},
                {"value": "CFB_0", "label": "Przepływy inwestycyjne"},
                {"value": "CFB_1", "label": "Zbycie rzeczowych aktywów trwałych oraz wartości niematerialnych"},
                {"value": "CFB_2", "label": "Nabycie rzeczowych aktywów trwałych oraz wartości niematerialnych"},
                {"value": "CFB_3", "label": "Zbycie udziałów w podmiotach powiązanych"},
                {"value": "CFB_4", "label": "Nabycie udziałów w podmiotach powiązanych"},
                {"value": "CFB_5", "label": "Zbycie nieruchomości"},
                {"value": "CFB_6", "label": "Nabycie nieruchomości"},
                {"value": "CFB_7", "label": "Zbycie/zapadalność aktywów finansowych"},
                {"value": "CFB_8", "label": "Nabycie aktywów finansowych"},
                {"value": "CFB_9", "label": "Zwrot udzielonych pożyczek"},
                {"value": "CFB_10", "label": "Udzielone pożyczki"},
                {"value": "CFB_11", "label": "Odsetki i dywidendy z działalności inwestycyjnej"},
                {"value": "CFB_12", "label": "Pozostałe przepływy inwestycyjne"},
                {"value": "CFB_13", "label": "Przepływy inwestycyjne z działalności zaniechanej"},
                {"value": "CFB_14", "label": "Wydatki na badania i rozwój"},
                {"value": "CFC_0", "label": "Przepływy finansowe"},
                {"value": "CFC_1", "label": "Emisja akcji własnych"},
                {"value": "CFC_2", "label": "Nabycie akcji własnych"},
                {"value": "CFC_3", "label": "Wyemitowane papiery dłużne"},
                {"value": "CFC_4", "label": "Wykup papierów dłużnych"},
                {"value": "CFC_5", "label": "Zaciągnięte kredyty i pożyczki"},
                {"value": "CFC_6", "label": "Spłacone kredyty i pożyczki"},
                {"value": "CFC_7", "label": "Dywidendy na rzecz udziałowców podmiotu dominującego"},
                {"value": "CFC_8", "label": "Płatności z tytułu leasingu"},
                {"value": "CFC_9", "label": "Inne wypłaty z zysku"},
                {"value": "CFC_10", "label": "Odsetki z działalności finansowej"},
                {"value": "CFC_11", "label": "Pozostałe przepływy finansowe"},
                {"value": "CFC_12", "label": "Przepływy finansowe z działalności zaniechanej"},
                {"value": "CFD_0", "label": "Przepływy pieniężne netto"},
                {"value": "CFD_1", "label": "Różnice kursowe"},
                {"value": "CFD_2", "label": "Bilansowa zmiana stanu środków pieniężnych"},
                {"value": "CFD_3", "label": "Środki pieniężne na początek okresu"},
                {"value": "CFD_4", "label": "Środki pieniężne na koniec okresu"}
            ]
        },
        {
            "label": "Wskaźniki rentowności",
            "options": [
                {"value": "RS_1", "label": "Marża zysku brutto ze sprzedaży"},
                {"value": "RS_2", "label": "Marża zysku operacyjnego"},
                {"value": "RS_3", "label": "Marża zysku brutto"},
                {"value": "RS_4", "label": "Marża zysku netto"},
                {"value": "RS_5", "label": "Stopa zwrotu z kapitału własnego [ROE]"},
                {"value": "RS_6", "label": "Stopa zwrotu z aktywów [ROA]"},
                {"value": "RS_7", "label": "Wskaźnik kapitału pracującego"},
                {"value": "RS_30", "label": "Marża odsetkowa"},
                {"value": "RS_31", "label": "Stopa aktywów odsetkowych"},
                {"value": "RS_32", "label": "Stopa pasywów odsetkowych"},
                {"value": "RS_33", "label": "Rozpiętość odsetkowa"},
                {"value": "RS_67", "label": "Wskaźnik rentowności działalności lokacyjnej"},
                {"value": "IS_RS_3", "label": "Marża zysku brutto [Marx]"}
            ]
        },
        {
            "label": "Wskaźniki płynności",
            "options": [
                {"value": "RS_8", "label": "Wskaźnik płynności bieżącej"},
                {"value": "RS_9", "label": "Wskaźnik płynności szybkiej"},
                {"value": "RS_10", "label": "Wskaźnik podwyższonej płynności"},
                {"value": "RS_34", "label": "Wskaźnik aktywów przychodowych"},
                {"value": "RS_35", "label": "Wskaźnik zobowiązań wobec klientów"},
                {"value": "RS_36", "label": "Wskaźnik kapitału własnego"},
                {"value": "RS_37", "label": "Pokrycie należności od klientów"},
                {"value": "RS_38", "label": "Wskaźnik finansowania aktywów nieprzychodowych"},
                {"value": "RS_39", "label": "Wskaźnik płynności MFW"},
                {"value": "RS_61", "label": "Wskaźnik płynności"}
            ]
        },
        {
            "label": "Wskaźniki zadłużenia",
            "options": [
                {"value": "RS_18", "label": "Wskaźnik pokrycia majątku"},
                {"value": "RS_19", "label": "Stopa zadłużenia"},
                {"value": "RS_20", "label": "Wskaźnik obsługi zadłużenia"},
                {"value": "RS_21", "label": "Wskaźnik zabezpieczenia długu"},
                {"value": "RS_40", "label": "Koszty działania / Wynik na działalności bankowej"},
                {"value": "RS_41", "label": "Koszty działania / Dochody z działalności podstawowej"},
                {"value": "RS_42", "label": "Koszty działania / Aktywa"},
                {"value": "RS_43", "label": "Produktywność majątku trwałego"}
            ]
        },
        {
            "label": "Wskaźniki sprawności działania",
            "options": [
                {"value": "RS_11", "label": "Rotacja należności"},
                {"value": "RS_12", "label": "Rotacja zapasów"},
                {"value": "RS_13", "label": "Cykl operacyjny"},
                {"value": "RS_14", "label": "Rotacja zobowiązań"},
                {"value": "RS_15", "label": "Cykl konwersji gotówki"},
                {"value": "RS_16", "label":  "Rotacja aktywów obrotowych"},
                {"value": "RS_17", "label": "Rotacja aktywów"},
                {"value": "RS_23", "label": "Wykorzystanie aktywów [AU]"}
            ] 
        },
        {
            "label": "Wskaźniki kondycji",
            "options": [
                {"value": "RS_80", "label": "Wskaźnik Altmana"},
                {"value": "RS_83", "label": "Wskaźnik Appenzeller-Szarzec"},
                {"value": "RS_86", "label": "Wskaźnik Mączyńskiej"},
                {"value": "RS_89", "label": "Wskaźnik Poznański"},
                {"value": "RS_92", "label": "Wskaźnik Tymoszuk"},
                {"value": "RS_95", "label": "Wskaźnik ZPANG INE PAN"},
                {"value": "RS_82", "label": "Znormalizowany wskaźnik Altmana"},
                {"value": "RS_85", "label": "Znormalizowana skala Appenzeller-Szarzec"},
                {"value": "RS_88", "label": "Znormalizowana skala Mączyńskiej"},
                {"value": "RS_91", "label": "Znormalizowana skala modelu Poznańskiego"},
                {"value": "RS_94", "label": "Znormalizowana skala Tymoszuk"},
                {"value": "RS_97", "label": "Znormalizowana skala ZPANG INE PAN"}
            ] 
        },
        {
            "label": "Pozostałe",
            "options": [
                {"value": "RS_22", "label": "Wskaźnik dźwigni finansowej [EM]"},
                {"value": "RS_24", "label": "Obciążenie zysku brutto"},
                {"value": "RS_25", "label": "Obciążenie zysku operacyjnego"},
                {"value": "RS_45", "label": "Wskaźnik kosztów odsetkowych [IER]"},
                {"value": "RS_46", "label": "Wskaźnik kosztów nieodsetkowych [NER]"},
                {"value": "RS_47", "label": "Wskaźnik pozostałych przychodów i kosztów [OR]"},
                {"value": "RS_48", "label": "Wskaźnik podatkowy [TR]"},
                {"value": "RS_50", "label": "Wskaźnik przychodów odsetkowych [IIR]"},
                {"value": "RS_51", "label": "Wskaźnik przychodów nieodsetkowych [NIR]"},
                {"value": "RS_62", "label": "Przychodowość aktywów"},
                {"value": "RS_63", "label": "Przychodowość kapitałów własnych"},
                {"value": "RS_64", "label": "Stopa kosztów technicznych brutto"},
                {"value": "RS_65", "label": "Szkodowość na udziale własnym"},
                {"value": "RS_66", "label": "Wskaźnik poziomu lokat"},
                {"value": "RS_68", "label": "Współczynnik zatrzymania ryzyka przez reasekuratora"},
                {"value": "RS_69", "label": "Stopa zabezpieczenia działalności ubezpieczeniowej"},
                {"value": "RS_70", "label": "Stopa rezerw technicznych"},
                {"value": "RS_71", "label": "Marża EBITDA"},
                {"value": "RS_72", "label": "EBITDA"},
                {"value": "RS_74", "label": "Krótkoterminowe zobowiązania finansowe"},
                {"value": "RS_75", "label": "Długoterminowe zobowiązania finansowe"},
                {"value": "RS_77", "label": "Zobowiązania finansowe ogółem"},
                {"value": "O_1", "label": "Liczba akcji [tys.]"},
                {"value": "O_3", "label": "Zysk na akcję"},
                {"value": "O_5", "label": "Wartość księgowa na akcję"},
            
                {"value": "IS_RS_1", "label": "CAPEX"},
                {"value": "IS_RS_2", "label": "Wolne przepływy pieniężne yield"},
                {"value": "IS_RS_5", "label": "Wzrost przychodów ze sprzedaży"},
                {"value": "IS_RS_6", "label": "Wskaźnik rozliczeń międzyokresowych [Przepływy pieniężne]"},
                {"value": "IS_RS_7", "label": "P/E"},
                {"value": "IS_RS_8", "label": "P/BV"},
                {"value": "IS_RS_9", "label": "P/CF"},
                {"value": "IS_RS_10", "label": "P/S"},
                {"value": "IS_RS_11", "label": "Enterprise value"},
                {"value": "IS_RS_12", "label": "Earnings yield"},
                {"value": "IS_RS_13", "label": "Liquidity Q.i."},
                {"value": "IS_RS_14", "label": "Średnia roczna wartość wolumenu"},
                {"value": "IS_RS_15", "label": "Średni pięcioletni ROIC"},
                {"value": "IS_RS_16", "label": "Short term momentum [3m]"},
                {"value": "IS_RS_17", "label": "Medium term momentum [6m]"},
                {"value": "IS_RS_18", "label": "Long term momentum [12m]"},
                {"value": "IS_RS_19", "label": "Book to market"},
                {"value": "IS_RS_20", "label": "Piotroski F-Score"},
                {"value": "IS_RS_21", "label": "Net fixed assets"},
                {"value": "IS_RS_22", "label": "Net working capital"},
                {"value": "IS_RS_23", "label": "Return on invested capital"},
                {"value": "IS_RS_24", "label": "Stopa dywidendy"},
                {"value": "IS_RS_25", "label": "Shareholder yield"},
                {"value": "IS_RS_26", "label": "Ciągłość dywidendy [lata]"},
                {"value": "IS_RS_27", "label": "Ciągłość rosnącej dywidendy na akcję [lata]"},
                {"value": "IS_RS_28", "label": "Ciągłość rosnącej stopy dywidendy [lata]"},
                {"value": "IS_RS_29", "label": "Kapitalizacja giełdowa"},
                {"value": "IS_RS_30", "label": "Altman Z-Score"},
                {"value": "IS_RS_31", "label": "Beta"},
                {"value": "IS_RS_32", "label": "Nakłady inwestycyjne"},
                {"value": "IS_RS_33", "label": "Suma rocznego obrotu"},
                {"value": "IS_RS_34", "label": "Średni miesięczny obrót [1y]"},
                {"value": "IS_RS_35", "label": "Wolne przepływy pieniężne"},
                {"value": "IS_RS_36", "label": "Dług ogółem"},
                {"value": "IS_RS_37", "label": "Dług krótkoterminowy"},
                {"value": "IS_RS_38", "label": "Dług długoterminowy"}
            ]
        }
    ]
}
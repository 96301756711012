import { useAuth } from 'react-oidc-context';
import NavBarModalItem from './NavBarModalItem';
import NavBarModalText from './NavBarModalText';
import NavBarModalButton from './NavBarModalButton';
import NavBarDesktopModalContainer from './NavBarDesktopModalContainer';
import NavBarModalSection from './NavBarModalSection';
import { useLocale } from '../../../hooks/useLocale';
import links from '../../../data/links.json';

interface AccountModalContentProps {
    isOpen: boolean
}

export default function AccountModalContent({ isOpen }: AccountModalContentProps) {

    const { texts } = useLocale();
    const { isAuthenticated, user, signinRedirect, signoutRedirect } = useAuth();

    if (isAuthenticated) {
        return (
            <NavBarDesktopModalContainer isOpen={isOpen}>
                <NavBarModalSection>
                    <NavBarModalText label={user?.profile.email} />
                </NavBarModalSection>
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelPortfoliosLink}`} label={texts.NAV_USER_PORTFOLIOS} />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelReportsLink}`} label={texts.NAV_USER_ADD_REPORT} isAdminOnly />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelInstrumentsLink}`} label={texts.NAV_USER_INSTRUMENTS} isAdminOnly />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelStrategiesLink}`} label={texts.NAV_USER_STRATEGIES} isAdminOnly />
                <NavBarModalItem action={() => signoutRedirect()} label={texts.ACCOUNT_LOG_OUT} />
            </NavBarDesktopModalContainer>
        );
    }

    return (
        <NavBarDesktopModalContainer isOpen={isOpen}>
            <NavBarModalSection>
                <NavBarModalButton label={texts.ACCOUNT_LOG_IN} action={() => signinRedirect()} />
            </NavBarModalSection>
            <NavBarModalItem action={() => signinRedirect()} label={texts.ACCOUNT_CREATE_ACCOUNT} />
        </NavBarDesktopModalContainer>
    );
}
import ErrorBoundary from './components/ErrorBoundary';
import { Message } from './components/Loading';
import { useLocale } from './hooks/useLocale';

interface AppErrorProps {
  children: React.ReactNode
}

export default function AppError({ children }: AppErrorProps) {

  const { texts } = useLocale();

  return (
    <ErrorBoundary fallback={<Message message={texts.GENERIC_ERROR_MESSAGE} />}>
      {children}
    </ErrorBoundary>
  );
}

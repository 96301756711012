import PortfolioImage from '../assets/pictograms/portfolio.svg';
import FormulaImage from '../assets/pictograms/formula.svg';
import InstrumentsImage from '../assets/pictograms/instruments.svg';
import MagnifierImage from '../assets/pictograms/magnifier.svg';
import ScannerImage from '../assets/pictograms/scanner.svg';
import CreatorImage from '../assets/pictograms/creator.svg';

export const localeEN: Internalization = {
    "locale": "en",
    "CURRENCY": "PLN",
    "YEAR_UNIT": "years",
    "WEEK": "week",
    "MONTH": "month",
    "MONTHS_VARIANT_1": "months",
    "MONTHS_VARIANT_2": "months",
    "YEAR": "year",
    "YEARS_VARIANT_1": "years",
    "YEARS_VARIANT_2": "years",
    "SEE_MORE_TEXT": "See more",
    "CONFIRM_CHANGES": "Confirm changes",

    "YES": "Yes",
    "NO": "No",
    "BACK": "Back",
    "NEXT": "Next",
    "FINISH": "Finish",
    "SAVE": "Save",
    "CANCEL": "Cancel",
    "CLOSE": "Close",
    "ALL": "All",
    "NONE": "None",
    "TOTAL": "Total",
    "EDIT": "Edit",

    "SELECT_DEFAULT_PLACEHOLDER": "--Select value--",

    "OFFLINE_WARNING_1": "You are offline",
    "OFFLINE_WARNING_2": "Check your Internet connection.",
    "EMPTY_PAGE_MESSAGE": "There is nothing here...",
    "GENERIC_ERROR_MESSAGE": "There was an error with InvestShark",
    "LOADING_MESSAGE": "Loading...",
    "LOADING_MESSAGE_NOT_SET": "Message not set.",
    "WAIT_MESSAGE": "Please wait...",

    "NO_RESULTS_FOUND": "No results found",

    "SEARCHING_NO_RESULTS": "No results found matching given parameters.",
    "SEARCHING_MESSAGE": "Searching...",
    "SEARCHING_ERROR": "Something went wrong :-(",
    "SEARCHING_NUMBER_OF_RESULTS": "Found results",

    "ACCOUNT_CREATE_ACCOUNT": "Create an account",
    "ACCOUNT_LOG_IN": "Log in",
    "ACCOUNT_LOG_OUT": "Log out",
    "ACCOUNT_MY_ACCOUNT": "My account",

    "NAV_BAR_HOME": "Home",
    "NAV_BAR_SAMPLE_PORTFOLIOS": "Portfolios",
    "NAV_BAR_INSTRUMENTS": "Instruments",
    "NAV_BAR_SCANNER": "Scanner",
    "NAV_BAR_STRATEGY_CREATOR": "Strategy Creator",
    "NAV_BAR_HELP": "Help",

    "NAV_USER_PORTFOLIOS": "Portfolios",
    "NAV_USER_ADD_REPORT": "Add financial report file",
    "NAV_USER_INSTRUMENTS": "Instruments",
    "NAV_USER_STRATEGIES": "Strategies",

    "FOOTER_TEXT_1": "Tymoteusz Trąpczyński",
    "FOOTER_TEXT_2": "tymon101@gmail.com",
    "FOOTER_TEXT_3": "NIP: 7831801539",
    "FOOTER_NOTE": "InvestShark, All rights reserved.",

    "ADMIN_PAGE_INSTRUMENTS_TITLE": "Instruments",
    "ADMIN_PAGE_STRATEGIES_TITLE": "Strategies",
    "ADMIN_PAGE_STRATEGIES_SUBTITLE": "Update strategies available in InvestShark",
    "ADMIN_PAGE_FINANCIAL_REPORTS_TITLE": "Add financial report",

    "USER_PAGE_TITLE": "User panel",
    "USER_PAGE_PORTFOLIOS_SUBTITLE": "Manage portfolios",

    "HELP_PAGE_TITLE": "Help",
    "HELP_PAGE_DESCRIPTION": "Not sure what an option does? Confused about a parameter? Find your answers here.",
    "HELP_TIMELINE_HEADER": "Start date and rebalance period",
    "HELP_FILTERS_ORDER_HEADER": "Setting filters",
    "HELP_MIRROR_IMAGE_HEADER": "Mirror image",
    "HELP_MAX_POSITIONS_HEADER": "Maximum number of companies",
    "HELP_COMMISSION_HEADER": "Commission rate",
    "HELP_SLIDER_HEADER": "Slider 0% - 100%",
    "HELP_FILTER_BUILD_HEADER": "Filter construction",

    "HOME_PAGE_TITLE": "InvestShark",
    "HOME_SEARCH_INSTRUMENT_HEADER": "Search instrument",
    "HOME_SEARCH_INSTRUMENT_PLACEHOLDER": "Ticker or name",

    "SCANNER_PAGE_TITLE": "Investment scanner",
    "SCANNER_PAGE_DESCRIPTION": "Use this tool to save time on analysis. Enter your criteria, and InvestShark will find all companies that meet them. You can add results to your watchlist and simulate or create a portfolio in the future.",
    "SCANNER_LOADING_MESSAGE": "Scanner is loading...",
    "SCANNER_LOADING_ERROR": "An error occurred",
    "SCANNER_GET_RESULTS": "Filter",

    "STRATEGY_CREATOR_PAGE_TITLE": "Strategy creator",
    "STRATEGY_CREATOR_PAGE_SECTION_BUILDER": "Creator",
    "STRATEGY_CREATOR_PAGE_SECTION_RESULTS": "Results",
    "STRATEGY_CREATOR_PAGE_SECTION_PORTFOLIO": "Portfolio",
    "STRATEGY_CREATOR_PORTFOLIO_NAME": "Strategy results",

    "SAMPLE_PORTFOLIOS_PAGE_TITLE": "Sample portfolios",
    "SAMPLE_PORTFOLIOS_PAGE_DESCRIPTION": "Below is a list of sample investment portfolios created using InvestShark. You can view the strategies used to create them in the Strategy Creator and use them to create your own. Remember, portfolio performance is not only about return rates. Never forget the risk.",

    "SHAREHOLDER_NOT_FOUND": "Shareholder not found or they don't have any shares",

    "home": {

        title: "InvestShark",
        subtitle: "The advanced creator of investing strategies for polish market",

        features: [
            {
                "id": 0,
                "title": "Filter-Based Creator",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                "img": "/images/home/strategycreator-params-screenshot.png",
                "imgDescription": "Some description",
                "linkTitle": "Try the Creator",
                "linkTo": "/strategyCreator",
                "pictogram": CreatorImage
            },
            {
                "id": 1,
                "title": "Current Preview of Selected Companies",
                "description": "InvestShark processes the strategy period by period, meaning you have an up-to-date view of which companies are being selected for the portfolio. Each filter is presented separately, allowing you to observe which companies passed through the filter and with what values.",
                "img": "/images/home/strategycreator-results-screenshot.png",
                "imgDescription": "Some description",
                "pictogram": MagnifierImage
            },
            {
                "id": 2,
                "title": "Advanced Filtering Factors",
                "description": "Used to selecting indicators from a list? What if a specific indicator isn't there? In InvestShark, you don't have to limit yourself to one indicator as a filtering factor; you can use an entire mathematical expression! Create a ranking from factors, choose the sorting direction, and many other options.",
                "img": "/images/home/portfolios-screenshot.png",
                "imgDescription": "Some description",
                "pictogram": FormulaImage
            },
            {
                "id": 3,
                "title": "Stock Scanner",
                "description": "Sometimes simpler tools are useful, and the scanner is just that. By selecting 5 different criteria, you can list a whole array of companies with the specified value. Values are sorted and color-coded.",
                "img": "/images/home/scanner-screenshot.png",
                "imgDescription": "Some description",
                "imgLocation": "left",
                "linkTitle": "Scanner",
                "linkTo": "/scanner",
                "pictogram": ScannerImage
            },
            {
                "id": 4,
                "title": "Condensed Financial Data",
                "description": "InvestShark is not just about strategies. If you're looking for financial data on a specific company or simply want to get more information about a company selected by the strategies, you can find basic information here. Financial data, shareholders, dividends, quotations are at your disposal. Choose the year and category to see results for specific quarters and the fiscal year. Additionally, you can immediately compare the value with other companies in the same sector.",
                "img": "/images/home/instruments-screenshot.png",
                "imgDescription": "Some description",
                "pictogram": InstrumentsImage
            },
            {
                "id": 5,
                "title": "Results as a Portfolio",
                "description": "View the results of the constructed strategy in the form of an investment portfolio. Compare individual periods of the strategy, browse individual companies. You also have performance measures such as Sharpe ratios, Alpha, and Beta at your disposal.",
                "linkTitle": "Sample Portfolios",
                "linkTo": "/smartBeta",
                "img": "/images/home/portfolios-screenshot.png",
                "imgDescription": "Portfolio chart and its performance parameters",
                "imgLocation": "left",
                "pictogram": PortfolioImage,
                "list": [
                    "Create your own virtual portfolios consisting of stocks, cash, and deposits",
                    "Strategy results as an investment portfolio",
                    "Export percentage changes of the portfolio and its composition to a CSV file",
                    "Don't know what to invest in? Ready-made portfolios await"
                ]
            }
        ]
    },

    "charts": {
        "labels": {
            "DATE": "Date",
            "VOLUME": "Volume",
            "PRICE": "Price",
            "CASH": "Cash",
            "CHANGE": "Return rate",
            "SHARE_PRICE": "Share price",
            "INVESTOR": "Investor",
            "SHARE": "Share",
            "INSTRUMENT": "Instrument",
            "PORTFOLIO": "Portfolio",
            "VALUE": "Value",
            "VALUE_CHANGE": "Value change"
        },
        "periods": {
            "ONE_MONTH_VALUE": "1m",
            "THREE_MONTHS_VALUE": "3m",
            "SIX_MONTHS_VALUE": "6m",
            "NINE_MONTHS_VALUE": "9m",
            "ONE_YEAR_VALUE": "1y",
            "TWO_YEARS_VALUE": "2y",
            "THREE_YEARS_VALUE": "3y",
            "FIVE_YEARS_VALUE": "5y",
            "TEN_YEARS_VALUE": "10y",
            "THIRTY_YEARS_VALUE": "30y"
        }
    },

    "financialReport": {
        "UPLOAD_NOTORIA_REPORT": "Upload report (Notoria xlsx)",

        "CREATE_IF_NOT_EXISTS": "Create if not exists",
        "GET_DIVIDENDS": "Get dividends",
        "GET_HISTORICAL": "Get historical data",
        "PROCESS_REPORT": "Process report",

        "TABLE_TICKER": "Ticker",
        "TABLE_TYPE": "Type",
        "TABLE_STATUS": "Status",

        "NO_REPORTS_IN_PROGRESS": "No reports are currently being processed"
    },

    "instruments": {
        "NOT_FOUND": "Instruments not found",

        "LATEST_TIMESTAMP": "Date of the latest quote",
        "GPW_COUNT": "Number of GPW companies",
        "NC_COUNT": "Number of NewConnect companies",
        "INSTRUMENTS_COUNT": "Number of companies",
        "QUOTES_TOTAL": "Number of quotes",
        "LAST_QUOTE": "Last quote",
        "UPDATE_STOCK_DATA": "Update historical data",
    
        "UPDATE_QUOTES": "Update quotes",
        "UPDATE_QUOTES_IN_PROGRESS": "Quote update in progress",
        "NO_DATA_FOUND": "No quotes found for the given date.",
    
        "UPDATE_IN_PROGRESS": "Instrument is being updated",
        "UPDATING": "updating",
        "NEVER_UPDATED": "never",
            
        "SEARCH_PLACEHOLDER_TEXT": "Instrument name or ticker",

        "PRICE_CHART_TITLE": "Stock price",
        "VOLUME_CHART_TITLE": "Volume (current session",

        "financialData": {
            "SECTION_HEADER": "Financial Data",
            "YEAR_LABEL": "Year",
            "CATEGORY_LABEL": "Categories",
            "TABLE_INDICATOR": "Position",
            "TABLE_QUATERS": "Quarterly",
            "TABLE_FIRST_QUATER": "Q1",
            "TABLE_SECOND_QUATER": "Q2",
            "TABLE_THIRD_QUATER": "Q3",
            "TABLE_FOURTH_QUATER": "Q4",
            "DATA_TABLE_YEAR": "Annual"
        },
        "investors": {
            "SECTION_HEADER": "Key Investors",
            "INFO_HEADER": "Information",
            "TABLE_NAME": "Name",
            "TABLE_DATE": "Date",
            "TABLE_NUMBER_OF_SHARES": "Number of Shares",
            "TABLE_NOTES": "Notes",
            "TABLE_TOTAL_SHARE": "Share",
            "NUMBER_OF_OPEN_PENSION_FUNDS": "Number of Investing OFEs",
            "OTHERS": "Others"
        },
        "dividends": {
            "SECTION_HEADER": "Dividends",
            "SIDE_INFO_HEADER": "Analysis"
        },
        "instrument": {
            "ADD": "Add instrument",
            "EDIT": "Edit instrument",
            "DELETE": "Delete instrument",
            "CONFIRM_DELETE": "Are you sure you want to delete the instrument",
            "PEEK": "Preview instrument",
    
            "ID": "Id",
            "TICKER": "Ticker",
            "SHORT_NAME": "Short name",
            "NAME": "Name",
            "WWW": "Website",
            "DATETIME": "Date",
            "CHANGE": "Change",
            "PREV_CLOSE": "Previous close",
            "OPEN": "Open",
            "LOW": "Lowest",
            "HIGH": "Highest",
            "CLOSE": "Close",
            "VOLUME": "Volume",
            "TRADING_VOLUME": "Turnover",
            "BETA": "Beta",
            "DESCRIPTION": "Description",
            "TYPE": "Type",
            "MARKET": "Market",
            "SECTOR": "Sector",
            "LAST_UPDATED": "Last updated",
            "DATA_SIZE": "Records",
    
            "SCANNER_RESULT_TITLE": "Sector overview (within the market)",
            "SCANNER_RESULT": "Average annual value in the sector"
        },
        "types": {
            "SHARES": "shares",
            "INDEXES": "indexes",
            "CONTRACTS": "contracts",
            "PORTFOLIOS": "portfolios",
            "BONDS": "bonds"
        }
    },

    "dividend": {
        "DIVIDEND_YIELD": "Dividend Yield",
        "DIVIDEND_PER_SHARE": "Dividend Per Share",
        "DIVIDEND_DAY": "Dividend Day",
        "DIVIDEND_CONTINUITY": "Dividend Continuity",
        "DIVIDEND_RAISING_CONTINUITY_PER_SHARE": "Rising Dividend Continuity Per Share",
        "DIVIDEND_RAISING_CONTINUITY_YIELD": "Rising Dividend Yield Continuity",
        "NUMBER_OF_DIVIDENDS": "Number of Dividends",
        "LAST_DIVIDEND": "Last Dividend",
        "UPDATING_MESSAGE": "Update Dividend Data"
    },

    "portfolios": {
        "FILE_COMPONENTS_SUFFIX": "_components.csv",
        "FILE_CHANGES_SUFFIX": "_value_changes.csv",
        "NUMBER_OF_PORTFOLIOS": "Number of Portfolios",
        "LOWEST_VALUE": "Lowest Value",
        "HIGHEST_VALUE": "Highest Value",
        "LOWEST_NUMBER_OF_INSTRUMENTS": "Fewest Instruments",
        "HIGHEST_NUMBER_OF_INSTRUMENTS": "Most Instruments",
        "BEST": "Best",
        "WORST": "Worst",
        "PIE_CHART_SHARE_OF_INTRUMENTS": "Share of Instruments",
        "PIE_CHART_SHARE_OF_SECTORS": "Share of Sectors",
        "PIE_CHART_SHARE_OF_MARKETS": "Share of Markets",
        "CHART_ADD_PERIOD": "Select Period:",
        "CHART_ADD_COMPONENT": "Add to Chart:",
        "CHART_ADD_INDEX": "Compare with:",
        "SECURITIES_SECTION_TITLE": "Securities",
        "CASH_SECTION_TITLE": "Cash",
        "DEPOSITS_SECTION_TITLE": "Deposits",
        "PERIOD": "Period",
        "PERIOD_NUMBER_OF_INSTRUMENTS": "Number of Instruments:",
        "PORTFOLIOS_LIST_NAME": "Portfolios List",
        "NOT_FOUND": "No Investment Portfolios Found",

        "portfolio": {
            "ADD": "Add Portfolio",
            "EDIT": "Edit Portfolio",
            "DELETE": "Delete Portfolio",
            "COPY": "Copy Portfolio",
            "CREATE_HINT": "Click the 'Add Portfolio' button to create a portfolio",
            "DELETE_MESSAGE": "Are you sure you want to delete this portfolio?",
            "NO_COMPONENTS_FOUND": "No positions in the portfolio or during the period.",
            "NAME": "Portfolio Name",
            "COPY_NAME": "Copy Portfolio Name",
            "STATUS": "Portfolio Status",
            "STATUS_PUBLIC": "Public",
            "STATUS_PRIVATE": "Private",
            "SIZE": "Size",
            "MIN_INVESTMENT": "Minimum Investment",
            "REBALANCE_PERIOD": "Portfolio Rebalance Period",
            "RETURN_PERIOD": "Return Period",
            "NUMBER_OF_POSITIONS": "Current Number of Positions",
            "NEXT_REBALANCE_DATE": "Next Rebalance Date",
            "RETURN": "Return",
            "TOTAL_RETURN": "Total Return",
            "ALPHA_COEFFICIENT": "Alpha Coefficient",
            "BETA_COEFFICIENT": "Beta Coefficient",
            "SHARPE_RATIO": "Sharpe Ratio",
            "DIVIDEND_SUM": "Dividend Sum",
            "COMMISSION_SUM": "Commission Sum",
            "DAILY_STANDARD_DEVIATION": "Daily Standard Deviation",
            "WEEKLY_STANDARD_DEVIATION_PAST_5_YEARS": "Weekly Standard Deviation (5 Years)",
            "LAST_CHANGE": "Last Change",
            "CURRENT_VALUE": "Current Value",
            "RISK_LEVEL": "Risk Level",
            "RISK_LEVEL_1": "Very Low",
            "RISK_LEVEL_2": "Low",
            "RISK_LEVEL_3": "Moderately Low",
            "RISK_LEVEL_4": "Moderate",
            "RISK_LEVEL_5": "Moderately High",
            "RISK_LEVEL_6": "High",
            "RISK_LEVEL_7": "Very High"
        },
    
        "portfolio_component": {
            "ADD": "Add Instrument",
            "EDIT": "Edit Instrument",
            "DELETE": "Delete Instrument",
            "BUY": "Buy Instrument",
            "SELL": "Sell Instrument",
            "DELETE_MESSAGE": "Are you sure you want to remove this instrument from the portfolio?",
            "NO_COMPONENTS_MESSAGE": "No stocks in the portfolio",
            "BUYING_DATE": "Buying Date",
            "BUYING_PRICE": "Buying Price",
            "BUYING_VALUE": "Buying Value",
            "SELLING_DATE": "Selling Date",
            "SELLING_PRICE": "Selling Price",
            "SELLING_VALUE": "Selling Value",
            "CURRENT_PRICE": "Current Price",
            "CURRENT_VALUE": "Current Value",
            "COMMISSION": "Commission",
            "NUMBER_OF_SHARES": "Number of Shares",
            "POSITION": "Position",
            "POSITION_LONG": "Long",
            "POSITION_SHORT": "Short",
            "DIVIDEND": "Dividend",
            "BALANCE": "Balance"
        },
    
        "portfolio_cash": {
            "ADD": "Add Cash",
            "EDIT": "Edit Cash",
            "DELETE": "Delete Cash",
            "DEPOSITED_WITHDRAWN_AMOUNT": "Deposited/Withdrawn Amount",
            "DATE": "Date",
            "CHANGE": "Cash Change",
            "DESCRIPTION": "Description",
            "INCOME": "Income",
            "BUY_WITH_CASH_MESSAGE": "Fund with Cash",
            "MOVE_TO_CASH": "Move to Cash",
            "DO_NOT_MOVE_TO_CASH": "Do Nothing",
            "NO_CASH_MESSAGE": "No cash in the portfolio.",
            "DELETE_MESSAGE": "Are you sure you want to delete the cash position from the portfolio for the given day?"
        },
    
        "portfolio_deposit": {
            "ADD": "Add Deposit",
            "EDIT": "Edit Deposit",
            "DELETE": "Delete Deposit",
            "TERMINATE": "Terminate Deposit",
            "DELETE_MESSAGE": "Are you sure you want to delete the following deposit from the portfolio?",
            "NO_DEPOSITS_MESSAGE": "No savings deposits in the portfolio.",
            "NAME": "Deposit Name",
            "INTEREST_RATE": "Interest Rate",
            "DURATION": "Deposit Duration in Months",
            "CAPITALIZATION_PERIOD": "Capitalization Period in Months",
            "TERMINATION_DATE": "Deposit Termination Date",
            "OPEN_DATE": "Deposit Open Date",
            "EXPIRE_DATE": "Deposit Expiry Date",
            "INVESTED_VALUE": "Invested Amount",
            "CURRENT_VALUE": "Current Value"
        }
    },

    "strategies": {
        "strategy": {
            "SAVE": "Save Strategy",
            "UPDATE": "Update",
            "EDIT": "Edit Parameters",
            "DELETE": "Delete Strategy",
            "DELETE_MESSAGE": "Are you sure you want to delete the strategy?",
            "ID": "ID",
            "NAME": "Strategy Name",
            "BEGINING_DATE": "Start Date",
            "REBALANCE_PERIOD": "Portfolio Rebalance Period (in months)",
            "COMMISSION_VALUE": "Commission Rate (in %)",
            "STANDARD": "Standard",
            "MIRROR_IMAGE": "Mirror Image",
            "MAX_POSITIONS": "Maximum Number of Companies",
            "MAX_QUOTE_AGE": "Quote Age (in days)",
            "LAST_UPDATED": "Last Updated",
            "ADDITIONAL_SETTINGS": "Strategy Options",
            "STATUS": "Status",
            "FILTERS_ORDER": "Filters Order",
            "FILTERS_ORDER_SEQUENTIONAL": "Sequential",
            "FILTERS_ORDER_PARALLEL": "Parallel",
            "CHOOSE": "Choose Strategy",
            "NUMBER_OF_COMPANIES": "Number of Companies",
            "PERIODIC_RESULTS": "Periodic Results",
            "NO_PERIODIC_RESULTS": "Results will be available after the first period processing is complete",
            "PROCESSING_TIME": "Processing Time",
            "INSTRUMENTS": "Instruments",
            "FINAL_NUMBER_OF_INSTRUMENTS": "Final list of companies and their share in portfolio",
            "USER_STRATEGIES": "User Strategies",
            "USER_STRATEGIES_LOGIN_MESSAGE": "Log in to save and load your own strategies",
            "USER_STRATEGIES_NOT_FOUND": "No Strategies Found",
            "ALREADY_EXISTS_MESSAGE": "A strategy with the given name already exists. Saving will replace it.",
            "NUMBER_OF_STRATEGIES": "Number of Available Strategies",
            "ERROR_INVALID_FILTERS": "At least one filter contains errors"
        },
        "strategyLoader": {
            "TABLE_HEADER_PERIOD": "Period",
            "TABLE_HEADER_POSITIONS": "Number of Companies",
            "TABLE_HEADER_ELAPSED_TIME": "Elapsed Time",
            "START_LOADING": "Start Processing",
            "STOP_LOADING": "Stop",
            "ESTIMATED_REMAINING_TIME": "Approximately Remaining",
            "IN_PROGRESS": "In Progress...",
            "PREPARE_TO_CANCEL_MESSAGE": "Wait...",
            "REQUEST_ID": "Request ID"
        },
        "filter": {
            "ADD": "Add Filter",
            "TYPE": "Type",
            "INDICATOR_TYPE": "Indicator",
            "OPERATOR_TYPE": "Operator",
            "NUMBER_TYPE": "Number",
            "UNKNOWN_TYPE": "Unknown",
            "FILTER_LABEL": "Filter",
            "FILTERS_LABEL": "Filters",
            "MARKETS_LABEL": "Markets",
            "SECTORS_LABEL": "Sectors",
            "YEAR_LABEL": "Year",
            "QUATER_LABEL": "Quarter",
            "FILTERING_FACTOR_LABEL": "Filtering Factor",
            "EMPTY_FILTER": "None",
            "RANGE": "Range",
            "SORT_LABEL": "Sort Values",
            "SORT_DESCENDING": "Descending",
            "SORT_ASCENDING": "Ascending",
            "MAX_POSITIONS_LABEL": "Maximum Number of Positions",
            "FILTERS_CLEAR": "Clear Filters",
            "FILTERS_CANNOT_BE_EMPTY": "Filters cannot be empty",
            "FILTERS_DRAG_AND_DROP_HINT": "Hint: You can swap filters by dragging them by their label.",
            "FACTOR": "Factor",
            "ADD_FACTOR": "Add Factor",
            "DELETE_FACTOR": "Delete Factor",
            "REQUIRE_ALL_FACTORS_LABEL": "Require All Factors",
            "RANKING": "Ranking",
            "ERROR_CHANGING_RANKING_TO_SINGLE": "Switching to a regular filter is only possible with one factor remaining",
            "ERROR_EVENT_TYPE": "Invalid event type",
            "ERROR_PARENTHESES_MISSING": "The number of opening and closing parentheses must be the same",
            "ERROR_INDICATORS_NEXT_TO_EACH_OTHER": "Indicators must be separated by a mathematical operator",
            "ERROR_OPERATORS_TO_CLOSE": "Mathematical operators must be separated by a number or an indicator",
            "ERROR_NUMBERS_NEXT_TO_EACH_OTHER": "Numbers cannot be directly next to each other",
            "ERROR_NUMBER_AND_INDICATOR_NEXT_TO_EACH_OTHER": "A number cannot be directly next to an indicator",
            "ERROR_PERIOD_WITHOUT_INDICATOR": "A period argument, e.g., [1y], can only follow an indicator",
            "ERROR_PERIOD_WRONG_FORMAT": "One or more period arguments are in the wrong format. Correct format: [1y]",
            "ERROR_MATH_OPERATOR_AT_THE_BEGINING": "A mathematical operator cannot be at the beginning",
            "ERROR_MATH_OPERATOR_AT_THE_END": "A mathematical operator cannot be at the end",
            "ERROR_NO_INDICATORS": "The expression must contain at least one indicator",
            "ERROR_INVALID_ELEMENTS": "Invalid elements detected"
        }
    },

    "LYNCH_RULES": [
        {"id": 1, "ruleText": "Gentlemen investing in bonds do not know what they are missing.", "ruleSource": "Peter Lynch's Rule II"},
        {"id": 2, "ruleText": "Never invest in an idea you can't illustrate with a pencil.", "ruleSource": "Peter Lynch's Rule III"},
        {"id": 3, "ruleText": "You can't see the future through a rearview mirror.", "ruleSource": "Peter Lynch's Rule IV"},
        {"id": 4, "ruleText": "There's no point paying Yo-Yo Ma to play the radio.", "ruleSource": "Peter Lynch's Rule V"},
        {"id": 5, "ruleText": "If you must choose a fund, you might as well choose stocks.", "ruleSource": "Peter Lynch's Rule VI"},
        {"id": 6, "ruleText": "The fancier the headquarters, the less inclined they are to richly reward shareholders.", "ruleSource": "Peter Lynch's Rule VII"},
        {"id": 7, "ruleText": "If long-term government bonds yield at least 6 percent more than the S&P 500 dividends, sell stocks and buy bonds.", "ruleSource": "Peter Lynch's Rule VIII"},
        {"id": 8, "ruleText": "Not all popular stocks are equally popular.", "ruleSource": "Peter Lynch's Rule IX"},
        {"id": 9, "ruleText": "Driving on the German autobahn, never look in the rearview mirror.", "ruleSource": "Peter Lynch's Rule X"},
        {"id": 10, "ruleText": "The best stock to buy may be the one you already own.", "ruleSource": "Peter Lynch's Rule XI"},
        {"id": 11, "ruleText": "The cure for taking stocks for granted is a sharp drop in their prices.", "ruleSource": "Peter Lynch's Rule XII"},
        {"id": 12, "ruleText": "Don't count on a comeback when they play the farewell.", "ruleSource": "Peter Lynch's Rule XIII"},
        {"id": 13, "ruleText": "If you like the store, chances are you'll love the stock.", "ruleSource": "Peter Lynch's Rule XIV"},
        {"id": 14, "ruleText": "Insider buying is a good sign - unless it happens at a New England bank.", "ruleSource": "Peter Lynch's Rule XV"},
        {"id": 15, "ruleText": "In business, competition is never as beneficial as total domination.", "ruleSource": "Peter Lynch's Rule XVI"},
        {"id": 16, "ruleText": "If two companies are indistinguishable, invest in the one with fewer color photos in the prospectus.", "ruleSource": "Peter Lynch's Rule XVII"},
        {"id": 17, "ruleText": "If analysts are bored with a stock, it's time to start buying it.", "ruleSource": "Peter Lynch's Rule XVIII"},
        {"id": 18, "ruleText": "If you're not looking for quick profits, nor a poetess seeking a rich husband, pessimism doesn't pay off.", "ruleSource": "Peter Lynch's Rule XIX"},
        {"id": 19, "ruleText": "Companies, like people, change their names either after a marriage or after a failure they want to forget quickly.", "ruleSource": "Peter Lynch's Rule XX"},
        {"id": 20, "ruleText": "When the queen sells, buy.", "ruleSource": "Peter Lynch's Rule XXI"}
    ],

    "FINANCIAL_LABELS": [
        {
            "label": "Assets",
            "options": [
                {"value": "A_0", "label": "Assets"},
                {"value": "AA_1", "label": "Non-current assets"},
                {"value": "AA_2", "label": "Current assets"},
                {"value": "A_1", "label": "Cash and central bank balances"},
                {"value": "A_2", "label": "Due from banks"},
                {"value": "A_3", "label": "Due from non financial customers"},
                {"value": "A_4", "label": "Insurance receivables"},
                {"value": "A_5", "label": "Reinsurance assets"},
                {"value": "A_6", "label": "Recourses and recoveries"},
                {"value": "A_7", "label": "Deffered acquisition costs"},
                {"value": "A_8", "label": "Derivative instruments"},
                {"value": "A_9", "label": "Non-current derivative instruments"},
                {"value": "A_10", "label": "Financial assets at fair value through profit or loss"},
                {"value": "A_11", "label": "Financial instruments held to maturity"},
                {"value": "A_12", "label": "Financial instruments available for sale"},
                {"value": "A_13", "label": "Investments in affiliates"},
                {"value": "A_14", "label": "Property plant and equipment"},
                {"value": "A_15", "label": "Investments property"},
                {"value": "A_16", "label": "Intangible assets"},
                {"value": "A_17", "label": "Goodwill"},
                {"value": "A_18", "label": "Deferred income tax"},
                {"value": "A_19", "label": "Assets from current tax"},
                {"value": "A_20", "label": "Current intangible assets"},
                {"value": "A_21", "label": "Financial assets"},
                {"value": "A_22", "label": "Non-current financial assets"},
                {"value": "A_23", "label": "Inventories"},
                {"value": "A_24", "label": "Trade receivables"},
                {"value": "A_25", "label": "Non-current loans and receivables"},
                {"value": "A_26", "label": "Accruals [Assets]"},
                {"value": "A_27", "label": "Non-current deferred charges and accruals"},
                {"value": "A_28", "label": "Cash and cash equivalents"},
                {"value": "A_29", "label": "Other assets"},
                {"value": "A_30", "label": "Other non-current assets"},
                {"value": "A_31", "label": "Assets held for sale and discontinuing operations"},
                {"value": "A_32", "label": "Biological assets"},
                {"value": "A_33", "label": "Loans and other receivables"},
                {"value": "A_34", "label": "Exploration for and evaluation of mineral resources"},
                {"value": "A_35", "label": "Financial assets held for trading"},
                {"value": "A_39", "label": "Own shares"},
                {"value": "A_40", "label": "Called up capital"}
            ]
        },
        {
            "label": "Equity and liabilities",
            "options": [
                {"value": "P_0", "label": "Equity and liabilities"},
                {"value": "PP_1", "label": "Equity shareholders of the parent"},
                {"value": "PP_2", "label": "Non-current liabilities"},
                {"value": "PP_3", "label": "Current liabilities"},
                {"value": "PP_4", "label": "Liabilities"},
                {"value": "P_1", "label": "Share capital"},
                {"value": "P_2", "label": "Called up share capital"},
                {"value": "P_3", "label": "Treasury shares"},
                {"value": "P_4", "label": "Supplementary capital"},
                {"value": "P_5", "label": "Valuation and exchange differences"},
                {"value": "P_6", "label": "Other capitals"},
                {"value": "P_7", "label": "Retained earnings / accumulated losses"},
                {"value": "P_8", "label": "Non-controlling interests"},
                {"value": "P_9", "label": "Due to Central Bank balances"},
                {"value": "P_10", "label": "Liabilities from derivatives"},
                {"value": "P_11", "label": "Non-current liabilities from derivatives"},
                {"value": "P_12", "label": "Due to banks"},
                {"value": "P_13", "label": "Due to customers"},
                {"value": "P_14", "label": "Financial liabilities at fair value through profit or loss"},
                {"value": "P_15", "label": "Financial liabilities held for trading"},
                {"value": "P_16", "label": "Liabilities from debt securities"},
                {"value": "P_17", "label": "Subordinated debt"},
                {"value": "P_18", "label": "Provisions"},
                {"value": "P_19", "label": "Non-current provision"},
                {"value": "P_20", "label": "Technical provisions"},
                {"value": "P_22", "label": "Deffered tax liabilities"},
                {"value": "P_23", "label": "Current tax liabilities"},
                {"value": "P_24", "label": "Other liabilities"},
                {"value": "P_25", "label": "Other non-current liabilities"},
                {"value": "P_26", "label": "Employee benefits"},
                {"value": "P_27", "label": "Long-term provision for employee benefits"},
                {"value": "P_28", "label": "Financial liabilities"},
                {"value": "P_29", "label": "Non-current loans and borrowings"},
                {"value": "P_30", "label": "Accruals [liablities]"},
                {"value": "P_31", "label": "Non-current accruals [liablities]"},
                {"value": "P_32", "label": "Trade payables"},
                {"value": "P_33", "label": "Non-current trade payables"},
                {"value": "P_34", "label": "Liabilities related to assets held for sale and discontinuing operations"},
                {"value": "P_35", "label": "Financial liabilities measured at amortised cost"},
                {"value": "P_40", "label": "Bond liabilities"},
                {"value": "P_41", "label": "Non-current liabilities from bonds"},
                {"value": "P_42", "label": "Liabilities from finance leases"},
                {"value": "P_43", "label": "Non-current liabilities from finance leases"},
                {"value": "xP_0", "label": "Capital adequacy ratio"}
            ]
        },
        {
            "label": "Incomes",
            "options": [
                {"value": "WO_1", "label": "Other operating revenues"},
                {"value": "WO_2", "label": "Other operating expenses"},
                {"value": "WO_3", "label": "Profit on non-financial assets"},
                {"value": "WO_4", "label": "Allowances for impairment"},
                {"value": "WO_6", "label": "Reversal of impairment"},
                {"value": "WO_0", "label": "Operating profit/loss"},
                {"value": "WB_1", "label": "Financial income"},
                {"value": "WB_2", "label": "Financial expenses"},
                {"value": "WB_3", "label": "Foreign exchange"},
                {"value": "WB_4", "label": "Net income on investments"},
                {"value": "WB_5", "label": "Extraordinary items"},
                {"value": "WB_6", "label": "Share in profit of associates and joint ventures"},
                {"value": "WB_7", "label": "Tax on financial institutions"},
                {"value": "WB_0", "label": "Profit/loss before tax"},
                {"value": "WN_1", "label": "Income tax"},
                {"value": "WN_2", "label": "Other events and deductions from profit"},
                {"value": "WN_3", "label": "Share in profit of associated companies"},
                {"value": "WN_4", "label": "Net profit/loss from discontinued operations"},
                {"value": "WN_0", "label": "Net profit/loss"},
                {"value": "xWN_1", "label": "Net profit/loss attributable to equity holders of the parent"},
                {"value": "xWN_2", "label": "Net profit/loss attributable to non-controling interests"},
                {"value": "CC_0", "label": "Other comprehensive income net"},
                {"value": "CC_1", "label": "Exchange differences from related affiliates"},
                {"value": "CC_2", "label": "Net income from valuation"},
                {"value": "CC_3", "label": "Cash flow hedges"},
                {"value": "CC_4", "label": "Other comprehensive income expenses"},
                {"value": "CC_5", "label": "Deffered tax on other comprehensive income"},
                {"value": "DD_0", "label": "Total comprehensive income"},
                {"value": "xDD_1", "label": "Total comprehensive income attributable to equity holders"},
                {"value": "xDD_2", "label": "Total comprehensive income attributable to non-controlling interests"},
                {"value": "AM_0", "label": "Depreciation [incomes]"},
                {"value": "xWB_2", "label": "Interest accrued"},
                {"value": "PD_0", "label": "Revenues from sales"},
                {"value": "PPD_0", "label": "Profit/loss on sales"},
                {"value": "PD_1", "label": "Revenues from sales of products and services"},
                {"value": "PD_2", "label": "Revenues from sales of goods and materials"},
                {"value": "PD_3", "label": "Other revenues from core activities"},
                {"value": "PPD_2", "label": "Gross profit/loss on sales"},
                {"value": "PD_4", "label": "Interest income"},
                {"value": "PPD_4", "label": "Net income from interest"},
                {"value": "PD_5", "label": "Fee and commission income"},
                {"value": "PPD_5", "label": "Net income from fees and commissions"},
                {"value": "PD_6", "label": "Premiums written gross"},
                {"value": "PPD_6", "label": "Premiums written net"},
                {"value": "PD_7", "label": "Reinsurers share of gross written insurance premium"},
                {"value": "PD_8", "label": "Change in insurance reserve premium"},
                {"value": "PD_10", "label": "Income from dividends"},
                {"value": "PD_13", "label": "Net income from investments"},
                {"value": "PD_14", "label": "Net income from financial instruments"},
                {"value": "PD_17", "label": "Net income from foreign exchange"},
                {"value": "PD_18", "label": "Net income from hedge accounting"},
                {"value": "PD_21", "label": "Revaluation of investments"},
                {"value": "KD_0", "label": "Costs of core operations"},
                {"value": "KKD_1", "label": "Own cost of sales"},
                {"value": "KD_1", "label": "Cost of sales of products and services"},
                {"value": "KD_2", "label": "Cost of goods and materials sold"},
                {"value": "KD_3", "label": "Cost of sales"},
                {"value": "KD_4", "label": "General and administrative costs"},
                {"value": "KD_5", "label": "Operating expenses [comparative]"},
                {"value": "xKD_5", "label": "Operating expenses"},
                {"value": "xKD_5_1", "label": "Depreciation costs"},
                {"value": "xKD_5_2", "label": "Raw material costs"},
                {"value": "xKD_5_3", "label": "Foreign service"},
                {"value": "xKD_5_4", "label": "Taxes and surcharges"},
                {"value": "xKD_5_5", "label": "Salaries"},
                {"value": "xKD_5_6", "label": "Social security and other"},
                {"value": "xKD_5_7", "label": "Other costs"},
                {"value": "xKD_5_8", "label": "Value of goods and materials sold"},
                {"value": "KD_6", "label": "Interest expenses"},
                {"value": "KD_7", "label": "Fee and commission expense"},
                {"value": "KKD_8", "label": "Paid claims and benefits, net"},
                {"value": "KD_8", "label": "Paid claims and benefits, gross"},
                {"value": "KD_9", "label": "Reinsurers share of claims and benefits paid"},
                {"value": "KD_10", "label": "Change in technical provisions reserves"},
                {"value": "KD_12", "label": "Revaluation of financial liabilities"},
                {"value": "KD_13", "label": "Acquisition costs"},
                {"value": "KD_14", "label": "General and administrative expenses [financial institutions]"}
            ]
        },
        {
            "label": "Cash flow",
            "options": [
                {"value": "CFA_0", "label": "Cash flow from operating activities"},
                {"value": "CFA_1", "label": "Financial result"},
                {"value": "CFA_2", "label": "Share of profit of associates companies"},
                {"value": "CFA_3", "label": "Depreciation [operating activities]"},
                {"value": "CFA_4", "label": "Exchange differences from operating activities"},
                {"value": "CFA_5", "label": "interest and dividends from operating activities"},
                {"value": "CFA_6", "label": "Net income from investments"},
                {"value": "CFA_7", "label": "Income tax paid"},
                {"value": "CFA_8", "label": "Share based payments"},
                {"value": "CFA_9", "label": "Change in reserves"},
                {"value": "CFA_10", "label": "Change in inventories"},
                {"value": "CFA_11", "label": "Change in receivables"},
                {"value": "CFA_12", "label": "Change in liabilities"},
                {"value": "CFA_13", "label": "Change in accruals"},
                {"value": "CFA_14", "label": "Change in other assets and liabilities"},
                {"value": "CFA_16", "label": "Other adjustments"},
                {"value": "CFA_17", "label": "Change in due from banks"},
                {"value": "CFA_18", "label": "Change in receivables from customers"},
                {"value": "CFA_19", "label": "Change in financial assets"},
                {"value": "CFA_20", "label": "Change in derivative instruments"},
                {"value": "CFA_21", "label": "Change in liabilities to banks"},
                {"value": "CFA_22", "label": "Change in amounts due to customers"},
                {"value": "CFA_23", "label": "Change in financial liabilities"},
                {"value": "CFA_28", "label": "Change in insurance receivables"},
                {"value": "CFA_29", "label": "Change in insurance liabilities"},
                {"value": "CFA_30", "label": "Net change in assets"},
                {"value": "CFA_31", "label": "Income tax deferred"},
                {"value": "CFA_40", "label": "Operating cash flow from discontinued operations"},
                {"value": "CFB_0", "label": "Cash flow from investing activities"},
                {"value": "CFB_1", "label": "Disposal of property plant equipment and intangible assets"},
                {"value": "CFB_2", "label": "Purchase of property plant equipment and intangible assets"},
                {"value": "CFB_3", "label": "Proceeds from disposal of shares in associates"},
                {"value": "CFB_4", "label": "Purchase of shares in associates"},
                {"value": "CFB_5", "label": "Proceeds from disposal of real estate"},
                {"value": "CFB_6", "label": "Purchase of real estate"},
                {"value": "CFB_7", "label": "Sale/maturity financial assets"},
                {"value": "CFB_8", "label": "Purchase of financial assets"},
                {"value": "CFB_9", "label": "Repayment of loans"},
                {"value": "CFB_10", "label": "Loans granted"},
                {"value": "CFB_11", "label": "Interest and dividends from investments"},
                {"value": "CFB_12", "label": "Other investment cash flows"},
                {"value": "CFB_13", "label": "Investment cash flows from discontinued operations"},
                {"value": "CFB_14", "label": "Expenditure on research and development"},
                {"value": "CFC_0", "label": "Cash flow from financing activities"},
                {"value": "CFC_1", "label": "Issue of shares"},
                {"value": "CFC_2", "label": "Purchase of own shares"},
                {"value": "CFC_3", "label": "Debt securities issued"},
                {"value": "CFC_4", "label": "Redemption of debt securities"},
                {"value": "CFC_5", "label": "Credits and loans taken"},
                {"value": "CFC_6", "label": "Payments of credits and loans taken"},
                {"value": "CFC_7", "label": "Dividends to the shareholders of the parent"},
                {"value": "CFC_8", "label": "Lease payments"},
                {"value": "CFC_9", "label": "Other profit sharing"},
                {"value": "CFC_10", "label": "Interest from financial activities"},
                {"value": "CFC_11", "label": "Other financing cash flow"},
                {"value": "CFC_12", "label": "Financial cash flows from discontinued operations"},
                {"value": "CFD_0", "label": "Net cash flow"},
                {"value": "CFD_1", "label": "Exchange rate differences"},
                {"value": "CFD_2", "label": "Balance sheet change in cash"},
                {"value": "CFD_3", "label": "Cash opening balance"},
                {"value": "CFD_4", "label": "Closing balance of cash"}
            ]
        },
        {
            "label": "Profitability indicators",
            "options": [
                {"value": "RS_1", "label": "Gross profit margin on sales"},
                {"value": "RS_2", "label": "Operating profit margin"},
                {"value": "RS_3", "label": "Gross profit margin"},
                {"value": "RS_4", "label": "Net profit margin"},
                {"value": "RS_5", "label": "Return on equity [ROE]"},
                {"value": "RS_6", "label": "Return on assets [ROA]"},
                {"value": "RS_7", "label": "Working capital ratio"},
                {"value": "RS_30", "label": "Net interest margin"},
                {"value": "RS_31", "label": "The interest rate of interest earning assets"},
                {"value": "RS_32", "label": "The interest rate of interest bearing liabilities"},
                {"value": "RS_33", "label": "The range of interest"},
                {"value": "RS_67", "label": "Profitability ratio of investment"},
                {"value": "IS_RS_3", "label": "Gross margin Marx"}
            ]
        },
        {
            "label": "Liquidity Indicators",
            "options": [
                {"value": "RS_8", "label": "Current ratio"},
                {"value": "RS_9", "label": "Quick ratio"},
                {"value": "RS_10", "label": "Cash ratio"},
                {"value": "RS_34", "label": "Earning assets ratio"},
                {"value": "RS_35", "label": "Ratio of liabilities to customers"},
                {"value": "RS_36", "label": "The equity ratio"},
                {"value": "RS_37", "label": "Coverage of loans and advances to customers"},
                {"value": "RS_38", "label": "Funding ratio of non-profitable assets"},
                {"value": "RS_39", "label": "Liquidity ratio IMF"},
                {"value": "RS_61", "label": "Liquidity ratio"}
            ]
        },
        {
            "label": "Debt indicators",
            "options": [
                {"value": "RS_18", "label": "Assets ratio"},
                {"value": "RS_19", "label": "Debt ratio"},
                {"value": "RS_20", "label": "Debt service ratio"},
                {"value": "RS_21", "label": "Rate debt security"},
                {"value": "RS_40", "label": "Operating cost / Net banking"},
                {"value": "RS_41", "label": "Operating expenses / income from core operations"},
                {"value": "RS_42", "label": "Operating cost / assets"},
                {"value": "RS_43", "label": "Productive assets"}
            ]
        },
        {
            "label": "Operational efficiency indicators",
            "options": [
                {"value": "RS_11", "label": "Recivables turnover"},
                {"value": "RS_12", "label": "Inventory turnover"},
                {"value": "RS_13", "label": "The operation cycle"},
                {"value": "RS_14", "label": "Rotation commitments"},
                {"value": "RS_15", "label": "Cash conversion cycle"},
                {"value": "RS_16", "label": "Rotation assets"},
                {"value": "RS_17", "label": "Assets turnover"},
                {"value": "RS_23", "label": "Asset utilization [AU]"}
            ] 
        },
        {
            "label": "Health indicators",
            "options": [
                {"value": "RS_80", "label": "Altman indicator"},
                {"value": "RS_83", "label": "Appenzeller-Szarzec indicator"},
                {"value": "RS_86", "label": "Mączyńska indicator"},
                {"value": "RS_89", "label": "Poznański indicator"},
                {"value": "RS_92", "label": "Tymoszuk indicator"},
                {"value": "RS_95", "label": "ZPANG INE PAN indicator"},
                {"value": "RS_82", "label": "Normalized Altman ratio"},
                {"value": "RS_85", "label": "Normalized Appenzeller-Szarzec scale"},
                {"value": "RS_88", "label": "Normalized Mączyńska scale"},
                {"value": "RS_91", "label": "Normalized Poznański scale"},
                {"value": "RS_94", "label": "Normalized Tymoszuk scale"},
                {"value": "RS_97", "label": "Normalized ZPANG INE PAN scale"}
            ] 
        },
        {
            "label": "Others",
            "options": [
                {"value": "RS_22", "label": "Leverage [EM]"},
                {"value": "RS_24", "label": "Load gross profit"},
                {"value": "RS_25", "label": "Load operating profit"},
                {"value": "RS_45", "label": "Interest expanse ratio [IER]"},
                {"value": "RS_46", "label": "Non-interest expanse ratio [NER]"},
                {"value": "RS_47", "label": "Ratio of other income and expenses"},
                {"value": "RS_48", "label": "Tax Rate [TR]"},
                {"value": "RS_50", "label": "Interest income ratio [IIR]"},
                {"value": "RS_51", "label": "Rate of non-interest revenue [NIR]"},
                {"value": "RS_62", "label": "Yield on assets"},
                {"value": "RS_63", "label": "Yield on equity"},
                {"value": "RS_64", "label": "The rate of gross technical costs"},
                {"value": "RS_65", "label": "Loss ratio net of reinsurance"},
                {"value": "RS_66", "label": "Investment level indicator"},
                {"value": "RS_68", "label": "Coefficient of risk retention by reinsurer"},
                {"value": "RS_69", "label": "The rate of insurance protection"},
                {"value": "RS_70", "label": "Rate of technical provisions"},
                {"value": "RS_71", "label": "EBITDA margin"},
                {"value": "RS_72", "label": "EBITDA"},
                {"value": "RS_74", "label": "Current financial liabilities"},
                {"value": "RS_75", "label": "Non-current financial liabilities"},
                {"value": "RS_77", "label": "Total financial liabilities"},
                {"value": "O_1", "label": "Number of shares"},
                {"value": "O_3", "label": "Earnings per share"},
                {"value": "O_5", "label": "Book value per share"},
            
                {"value": "IS_RS_1", "label": "CAPEX"},
                {"value": "IS_RS_2", "label": "Free cash flow yield"},
                {"value": "IS_RS_4", "label": "Free cash flow / Debt"},
                {"value": "IS_RS_5", "label": "Revenues from sales growth"},
                {"value": "IS_RS_6", "label": "Accrual ratio [Cash Flow]"},
                {"value": "IS_RS_7", "label": "P/E"},
                {"value": "IS_RS_8", "label": "P/BV"},
                {"value": "IS_RS_9", "label": "P/CF"},
                {"value": "IS_RS_10", "label": "P/S"},
                {"value": "IS_RS_11", "label": "Enterprise value"},
                {"value": "IS_RS_12", "label": "Earnings yield"},
                {"value": "IS_RS_13", "label": "Liquidity Q.i."},
                {"value": "IS_RS_14", "label": "Average yearly trading volume"},
                {"value": "IS_RS_15", "label": "Average five years ROIC"},
                {"value": "IS_RS_16", "label": "Short term momentum [3m]"},
                {"value": "IS_RS_17", "label": "Medium term momentum [6m]"},
                {"value": "IS_RS_18", "label": "Long term momentum [12m]"},
                {"value": "IS_RS_19", "label": "Book to market"},
                {"value": "IS_RS_20", "label": "Piotroski F-Score"},
                {"value": "IS_RS_21", "label": "Net fixed assets"},
                {"value": "IS_RS_22", "label": "Net working capital"},
                {"value": "IS_RS_23", "label": "Return on invested capital"},
                {"value": "IS_RS_24", "label": "Dividend yield"},
                {"value": "IS_RS_25", "label": "Shareholder yield"},
                {"value": "IS_RS_26", "label": "Dividend continuity [years]"},
                {"value": "IS_RS_27", "label": "Dividend continuity and raising per share [years]"},
                {"value": "IS_RS_28", "label": "Dividend continuity and raising yield [years]"},
                {"value": "IS_RS_29", "label": "Market capitalization"},
                {"value": "IS_RS_30", "label": "Altman Z-Score"},
                {"value": "IS_RS_31", "label": "Beta"},
                {"value": "IS_RS_32", "label": "Investment outlays"},
                {"value": "IS_RS_33", "label": "Yearly trading volume sum"},
                {"value": "IS_RS_34", "label": "Average Monthly Trading Volume [1y]"}
            ]
        }
    ]
}
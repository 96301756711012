import styles from './HomeFeature.module.css';
import { Button } from '../../../components/button/Button';

interface HomeFeatureProps {
    id: number
    title: string
    description: string
    linkTo?: string
    linkTitle?: string
    img?: string
    imgDescription?: string
    imgLocation?: string
    pictogram: string
    list?: string[]
}

export default function HomeFeature({ id, title, description, linkTo, linkTitle, img, imgDescription, imgLocation = "right", pictogram, list }: HomeFeatureProps) {

    return (
        <div className={styles['container']} key={id} id={id.toString()}>
            <div className={[styles['content'], styles[`image-${imgLocation}`]].join(' ')}>
                <div className={styles['main']}>
                    <div>
                        <div className={styles['header']}>
                            <img className={styles['pictogram']} src={pictogram} alt="Pictogram" />
                            <h2>{title}</h2>
                        </div>
                        <p>{description}</p>
                        {list && <ul>
                            {list.map(listItem => {
                                return (
                                    <li key={listItem}>{listItem}</li>
                                )
                            })}
                        </ul>}
                        {(linkTo && linkTitle) && <Button link={linkTo}>{linkTitle}</Button>}
                    </div>
                </div>
                {img && <img className={styles['image']} src={img} alt={imgDescription} />}
            </div>
        </div>
    )
}
import NavBarModalText from './NavBarModalText';
import NavBarDesktopModalContainer from './NavBarDesktopModalContainer';
import NavBarModalSection from './NavBarModalSection';
import { useLocale } from '../../../hooks/useLocale';
import links from '../../../data/links.json';
import Select, { SelectInstance } from 'react-select';
import selectStyles from '../../../styles/selectStyles';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message, SmallLoading } from '../../Loading';

interface SearchModalDesktopProps {
    isOpen: boolean,
    instrumentsLoaded: boolean,
    instrumentsList: FinancialInstrumentSelect[]
}

export default function SearchModalDesktop({ isOpen, instrumentsLoaded, instrumentsList }: SearchModalDesktopProps) {
    const { texts } = useLocale();
    const [selectedInstrument, setSelectedInstrument] = useState<unknown>(null);
    let navigate = useNavigate();
    const selectRef = useRef<SelectInstance>(null);

    const onSelectChange = (instrument: unknown) => {
        setSelectedInstrument(instrument);
        const financialInstrument = instrument as FinancialInstrumentSelect;
        navigate(`/${links.instrumentsLink}/${financialInstrument.value}`);
    }

    const getSearchComponent = () => {
        if (!instrumentsLoaded) {
            return (
                <SmallLoading hideMessage />
            )
        }

        if (instrumentsList.length === 0) {
            return (
                <Message message={texts.NO_RESULTS_FOUND} />
            )
        }

        if (isOpen) {
            selectRef.current?.focus();
        }

        return (
            <Select
                styles={selectStyles}
                ref={selectRef}
                openMenuOnFocus
                placeholder={texts.HOME_SEARCH_INSTRUMENT_PLACEHOLDER}
                value={selectedInstrument}
                options={instrumentsList}
                onChange={(instrument) => onSelectChange(instrument)} />
        )
    }

    return (
        <NavBarDesktopModalContainer isOpen={isOpen}>
            <NavBarModalSection>
                <NavBarModalText label={texts.HOME_SEARCH_INSTRUMENT_HEADER} />
                {getSearchComponent()}
            </NavBarModalSection>
        </NavBarDesktopModalContainer>
    );
}
import styles from './MobileModalClose.module.css';

interface MobileModalCloseProps {
    position?: 'left' | 'right',
    onCloseClick: () => void
}

export default function MobileModalClose({ onCloseClick, position = 'left' }: MobileModalCloseProps) {
    return (
        <span className={[styles['navbar-modalClose'], styles[position]].join(" ")} onClick={onCloseClick}>
            <div className={styles['navbar-icon']}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" focusable={false}>
                    <use href='#navbar-icon-close' />
                </svg>
            </div>
        </span>
    );
}
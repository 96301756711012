import styles from './MenuIcon.module.css';

export default function MenuLogo() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.15 68.46" className={styles['navbar-embedded']}>
            <g id="navbar-icon-logo">
                <path
                    d="m 116.48403,27.787025 c -2.19332,4.837604 -7.96544,9.223644 -11.73387,11.522428 -5.775807,5.175969 -13.310763,8.771675 -21.084494,11.037325 -14.290437,1.886791 -22.51008,0.791873 -37.029369,-8.695953 -0.24974,0.01931 -0.420812,0.156625 -0.437692,0.525225 0.272982,0.646549 0.908093,1.148247 0.08754,2.232206 -2.674771,2.542044 -5.355127,5.180618 -6.740454,9.935507 L 38.49523,56.707275 C 35.139591,55.189959 33.76816,51.688457 34.380928,46.202774 35.136189,42.48243 36.523383,39.521793 38.101308,35.041743 38.251071,34.5239 37.602315,33.756683 37.225925,33.028382 36.049524,32.927361 36.59286,32.341727 36.39431,31.3214 35.261926,32.032098 35.501818,31.500981 35.431388,30.008338 34.011403,29.838853 34.515382,29.701104 34.424697,28.432661 33.737556,27.953566 33.13849,25.515357 32.192468,24.099556 30.507527,21.56628 31.220017,20.420477 30.441701,17.928162 29.81786,15.821072 29.491573,14.119293 29.43501,12.238225 29.35421,9.5503757 29.215472,6.5632232 30.4417,3.2218627 31.306684,1.1670765 32.721174,1.0978583 33.374236,1.3835749 35.158258,2.4293585 36.652201,3.5476616 37.97,4.71 c 1.272231,1.5384913 2.725798,2.0872975 3.982996,4.2018004 1.122547,3.2994126 3.562982,6.4873396 5.689993,10.3294236 0.704762,1.108459 2.4228,2.660248 3.939226,4.464413 3.165971,-1.529533 6.025557,-3.356599 9.191528,-4.114262 4.167964,0.09188 9.491892,0.837711 10.373297,4.508181 -5.290475,2.160812 -8.599172,2.995278 -11.117372,7.878375 3.907625,3.309935 8.432955,5.910594 13.743523,7.7033 5.725987,2.201877 7.318971,2.955373 10.723449,2.713662 4.879885,-0.06949 11.540288,-1.803961 15.88822,-4.770794 1.52967,-3.16392 2.13924,-6.695877 3.15137,-10.066811 0.30794,-0.932622 0.65038,-5.316723 2.40731,-5.033408 1.87851,1.825694 1.80912,7.936733 2.71368,11.9051 2.8126,-2.098059 4.9006,-4.497626 6.78423,-6.827923 0.8056,0.01482 0.80663,-0.02931 1.04258,0.185968 z"
                />
                <text
                    x="43"
                    y="67"
                >InvestShark</text>
            </g>
        </svg >
    );
}
import React from 'react';
import { localePL } from '../data/locale-pl';
import { localeEN } from '../data/locale-en';

export const LocaleContext = React.createContext<InternalizationContextType | null>(null);

const getLocaleContent = (languageCode: string) => {
    switch (languageCode) {
        case "pl-pl":
            return localePL;
        case "en-us":
            return localeEN;
        default:
            return localePL;
    }
}

const LocaleProvider: React.FC<{ languageCode: string, children: React.ReactNode }> = ({ languageCode, children }) => {
    const localeTexts = getLocaleContent(languageCode);
    return (React.createElement(LocaleContext.Provider, { value: { code: localeTexts?.locale, texts: localeTexts } }, children));
};

export default LocaleProvider;
import { useLocale } from '../../../hooks/useLocale';
import styles from './HomeHero.module.css';
import HomePictogram from './HomePictogram';
import logo from '../../../assets/logo.svg';

export default function HomeHero() {

    const { texts } = useLocale();
    const features = texts.home.features;

    return (
        <section style={{ backgroundImage: 'url(/images/home/home-hero-img.png)' }} className={styles['hero']}>
            <div className={styles['inner']}>
                <div className={styles['main']}>
                    <div className={styles['wrapper']}>
                        <img className={styles['pictogram']} src={logo} alt="Pictogram" />
                        <h1>{texts.home.title}</h1>
                        <p>{texts.home.subtitle}</p>
                    </div>
                </div>
                <div className={styles['hero-lower']}>
                    <div className={styles['column']}>
                        <HomePictogram key={features[0].id} id={features[0].id.toString()} img={features[0].pictogram} title={features[0].title} />
                        <HomePictogram key={features[1].id} id={features[1].id.toString()} img={features[1].pictogram} title={features[1].title} />
                    </div>
                    <div className={[styles['column'], styles['column-huge-spaces']].join(' ')}>
                        <HomePictogram key={features[3].id} id={features[2].id.toString()} img={features[2].pictogram} title={features[2].title} />
                        <div></div>
                        <HomePictogram key={features[3].id} id={features[3].id.toString()} img={features[3].pictogram} title={features[3].title} />
                    </div>
                    <div className={styles['column']}>
                        <HomePictogram key={features[4].id} id={features[4].id.toString()} img={features[4].pictogram} title={features[4].title} />
                        <HomePictogram key={features[5].id} id={features[5].id.toString()} img={features[5].pictogram} title={features[5].title} />
                    </div>
                </div>
            </div>
        </section >
    )
}
import styles from './HomePictogram.module.css';
import PortfolioImage from '../../../assets/pictograms/portfolio.svg';

interface HomePictogramProps {
    id: string
    title: string
    img: string
}

export default function HomePictogram({ id, title, img }: HomePictogramProps) {

    return (
        <div className={styles['container']}>
            <img className={styles['pictogram']} src={img ? img : PortfolioImage} alt="Pictogram" />
            <h3>{title}</h3>
        </div>
    );
}
import axios, { AxiosRequestConfig } from 'axios';

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_REST_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    }
})

axiosClient.interceptors.response.use(response => {
    if (response.data && typeof response.data === 'object') {
        if (Array.isArray(response.data)) {
            response.data.forEach(item => {
                convertDateStringsToDates(item);
            });
        } else {
            convertDateStringsToDates(response.data);
        }
    }
    return response;
}, error => {
    return Promise.reject(error);
});

const convertDateStringsToDates = (input: any) => {
    if ((typeof input === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(input))
    || (typeof input === 'string' && /^\d{4}-\d{2}-\d{2}/.test(input))) {
        return new Date(input);
    } else if (typeof input === 'object') {
        for (const key in input) {
            if (Object.prototype.hasOwnProperty.call(input, key)) {
                input[key] = convertDateStringsToDates(input[key]);
            }
        }
    }
    return input;
}

const dividendPath = '/dividends/';
const financialComponentDataPath = '/financialComponentData/';
const financialDataPath = '/financialData/';
const instrumentPath = '/instruments/';
const marketPath = '/markets/';
const portfolioCashPath = '/portfolioCash/';
const portfolioSharePath = '/portfolioShare/';
const portfolioDepositPath = '/portfolioDeposit/';
const portfolioLoaderPath = '/portfolioLoader/';
const portfolioPath = '/portfolio/';
const sectorPath = '/sectors/';
const shareholderPath = '/shareholders/';
const stockDataPath = '/stockData/';
const strategyPath = '/strategies/';



export const dividendEndpoint = {
    add: (data: Dividend, config: AxiosRequestConfig) => axiosClient.post(instrumentPath, JSON.stringify(data), config),
    update: (id: number, data: Dividend, config: AxiosRequestConfig) => axiosClient.put(instrumentPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(instrumentPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<Dividend>(instrumentPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<Dividend[]>(instrumentPath, config),
    getDividendData: (config: AxiosRequestConfig) => axiosClient.get<FullDividendData>(dividendPath + 'getDividendData', config),
    requestDividendData: (config: AxiosRequestConfig) => axiosClient.get(dividendPath + 'requestDividendData', config)
}

export const financialComponentDataEndpoint = {
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<FinancialComponentData[]>(financialComponentDataPath + `${id}`, config),
}

export const financialDataEndpoint = {
    addMany: (data: FinancialReportsDataToInsert, config: AxiosRequestConfig) => axiosClient.post(financialDataPath + 'addMany', JSON.stringify(data), config),
    getFinancialData: (config: AxiosRequestConfig) => axiosClient.get<FinancialData[]>(financialDataPath + 'getFinancialData', config),
    getYears: (config: AxiosRequestConfig) => axiosClient.get<number[]>(financialDataPath + 'getYears', config)
}

export const instrumentEndpoint = {
    add: (data: InstrumentToInsert, config: AxiosRequestConfig) => axiosClient.post(instrumentPath, JSON.stringify(data), config),
    update: (id: number, data: InstrumentToUpdate, config: AxiosRequestConfig) => axiosClient.put(instrumentPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(instrumentPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<FinancialInstrument>(instrumentPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<FinancialInstrument[]>(instrumentPath, config),
    getAllIndexes: (config: AxiosRequestConfig) => axiosClient.get<FinancialIndex[]>(instrumentPath + 'getIndexes', config),
    getFreshData: (config: AxiosRequestConfig) => axiosClient.get(instrumentPath + 'getFreshData', config),
    addFromReport: (data: InstrumentToInsertReport, config: AxiosRequestConfig) => axiosClient.post(instrumentPath + 'addFromReport', JSON.stringify(data), config),
}

export const marketEndpoint = {
    add: (data: MarketCreate, config: AxiosRequestConfig) => axiosClient.post(marketPath, JSON.stringify(data), config),
    update: (id: number, data: Market, config: AxiosRequestConfig) => axiosClient.put(marketPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(marketPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<Market>(marketPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<Market[]>(marketPath, config)
}

export const portfolioCashEndpoint = {
    add: (data: PortfolioCashToInsert, config: AxiosRequestConfig) => axiosClient.post(portfolioCashPath, JSON.stringify(data), config),
    update: (id: number, data: PortfolioCash, config: AxiosRequestConfig) => axiosClient.put(portfolioCashPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(portfolioCashPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<PortfolioCash>(portfolioCashPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<PortfolioCash[]>(portfolioCashPath, config)
}

export const portfolioShareEndpoint = {
    add: (data: PortfolioShareToInsertUpdate, config: AxiosRequestConfig) => axiosClient.post(portfolioSharePath, JSON.stringify(data), config),
    update: (id: number, data: PortfolioShareToInsertUpdate, config: AxiosRequestConfig) => axiosClient.put(portfolioSharePath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(portfolioSharePath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<PortfolioShare>(portfolioSharePath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<PortfolioShare[]>(portfolioSharePath, config),
    sell: (id: number, data: PortfolioShareToInsertUpdate, config: AxiosRequestConfig) => axiosClient.patch(portfolioSharePath + `sell/${id}`, JSON.stringify(data), config)
}

export const portfolioDepositEndpoint = {
    add: (data: PortfolioDepositToInsert, config: AxiosRequestConfig) => axiosClient.post(portfolioDepositPath, JSON.stringify(data), config),
    update: (id: number, data: PortfolioDepositToUpdate, config: AxiosRequestConfig) => axiosClient.put(portfolioDepositPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(portfolioDepositPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<PortfolioDeposit>(portfolioDepositPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<PortfolioDeposit[]>(portfolioDepositPath, config),
    depositChartData: (id: number, config: AxiosRequestConfig) => axiosClient.get<PortfolioDepositChartData[]>(portfolioDepositPath + `depositChartData/${id}`, config)
}

export const portfolioLoaderEndpoint = {
    setup: (data: PortfolioLoaderSetup, config: AxiosRequestConfig) => axiosClient.post<PortfolioLoaderPayload>(portfolioLoaderPath, JSON.stringify(data), config),
}

export const portfolioEndpoint = {
    add: (data: PortfolioToInsert, config: AxiosRequestConfig) => axiosClient.post(portfolioPath, JSON.stringify(data), config),
    update: (id: number, data: PortfolioToUpdate, config: AxiosRequestConfig) => axiosClient.put(portfolioPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(portfolioPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<Portfolio>(portfolioPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<Portfolio[]>(portfolioPath, config),
    getAllPublic: (config: AxiosRequestConfig) => axiosClient.get<Portfolio[]>(portfolioPath + 'allPublic', config),
    copy: (id: number, data: PortfolioToCopy, config: AxiosRequestConfig) => axiosClient.post(portfolioPath + `${id}`, JSON.stringify(data), config)
}

export const sectorEndpoint = {
    add: (data: SectorCreate, config: AxiosRequestConfig) => axiosClient.post(sectorPath, JSON.stringify(data), config),
    update: (id: number, data: Sector, config: AxiosRequestConfig) => axiosClient.put(sectorPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(sectorPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<Sector>(sectorPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get<Sector[]>(sectorPath, config)
}

export const shareholderEndpoint = {
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get(shareholderPath + `${id}`, config),
    getInstrumentShareholders: (id: number, config: AxiosRequestConfig) => axiosClient.get<ShareholderInstruments[]>(shareholderPath + `getInstrumentShareholders/${id}`, config),
    addMany: (data: ShareholdersToInsert[], config: AxiosRequestConfig) => axiosClient.post(shareholderPath + `addMany`, JSON.stringify(data), config),
    getShareholderInstruments: (id: number, config: AxiosRequestConfig) => axiosClient.get(shareholderPath + `getShareholderInstruments/${id}`, config)
}

export const stockDataEndpoint = {
    add: (data: StockDataToInsert, config: AxiosRequestConfig) => axiosClient.post(stockDataPath, JSON.stringify(data), config),
    update: (id: number, data: StockDataToUpdate, config: AxiosRequestConfig) => axiosClient.put(stockDataPath + `${id}`, JSON.stringify(data), config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(stockDataPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get<StockData>(stockDataPath + `${id}`, config),
    getAll: (id: number, config: AxiosRequestConfig) => axiosClient.get<StockData[]>(stockDataPath + `getAll/${id}`, config),
    getOnDate: (config: AxiosRequestConfig) => axiosClient.get<StockData>(stockDataPath + 'getOnDate', config),
    getLatest: (config: AxiosRequestConfig) => axiosClient.get<StockData>(stockDataPath + 'getLatest', config),
    requestUpdate: (config: AxiosRequestConfig) => axiosClient.get(stockDataPath + 'requestUpdate', config)
}

export const strategyEndpoint = {
    add: (data: any, config: AxiosRequestConfig) => axiosClient.post(strategyPath, data, config),
    update: (id: number, data: any, config: AxiosRequestConfig) => axiosClient.put(strategyPath + `${id}`, data, config),
    delete: (id: number, config: AxiosRequestConfig) => axiosClient.delete(strategyPath + `${id}`, config),
    get: (id: number, config: AxiosRequestConfig) => axiosClient.get(strategyPath + `${id}`, config),
    getAll: (config: AxiosRequestConfig) => axiosClient.get(strategyPath, config),
    getDefaultStrategies: (config: AxiosRequestConfig) => axiosClient.get(strategyPath + 'getPublic', config),
    getScannerResults: (data: ScannerPayload, config: AxiosRequestConfig) => axiosClient.post(strategyPath + 'getScannerResults', data, config),
    executeStrategy: (data: StrategyExecutorSetup, config: AxiosRequestConfig) => axiosClient.post<StrategyExecutorPayload>(strategyPath + 'executeStrategy', JSON.stringify(data), config),
    cancelExecution: (data: StrategyExecutorPayload, config: AxiosRequestConfig) => axiosClient.post(strategyPath + 'cancelExecution', JSON.stringify(data), config),
    checkExecutionStatus: (config: AxiosRequestConfig) => axiosClient.get<StrategyStatus[]>(strategyPath + 'checkExecutionStatus', config),
}
import { StylesConfig } from "react-select";

const selectCustomStyles: StylesConfig = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1e90ff' : state.isFocused ? 'rgb(25 49 82)' : 'black',
        color: state.isSelected ? 'white' : state.isFocused ? 'white' : '#aaa',
        borderBottom: '1px solid rgba(30 ,77, 107, 0.50)',
        padding: 15
    }),
    group: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(30 ,77, 107, 0.50)',
        color: '#aaa'
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: '13px',
        backgroundColor: 'rgba(30 ,77, 107, 0.50)',
        color: '#aaa',
        zIndex: 9999
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        fontSize: '13px',
        color: state.isFocused ? 'white' : '#aaa',
        backgroundColor: 'rgba(30 ,77, 107, 0.50)',
        borderColor: 'black'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'white'
    }),
    input: (provided) => ({
        ...provided,
        color: 'white'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'none',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        borderRadius: '4px',
        backgroundColor: 'black',
        color: 'white'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : 'red'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        borderRadius: '4px',
        color: state.isFocused ? 'white' : 'red',
        backgroundColor: 'black'
    }),
};

export default selectCustomStyles;
import styles from './MenuIcon.module.css';

export default function MenuIcons() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={styles['navbar-embedded']}>
            <g id="navbar-icon-menu">
                <rect x="15" y="16" width="24" height="3"></rect>
                <rect x="15" y="23" width="24" height="3"></rect>
                <rect x="15" y="30" width="24" height="3"></rect>
            </g>
            <g id="navbar-icon-userProfile">
                <ellipse
                    ry="4.4273047"
                    rx="4.3800573"
                    cy="20.128628"
                    cx="24.155411" />
                <path
                    d="m 14,33 c 6.161514,-7.650775 14.010716,-7.458847 20.41,0"
                />
            </g>
        </svg>
    );
}
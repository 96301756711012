import Moment from 'react-moment';
import 'moment-timezone';
import { useLocale } from '../../hooks/useLocale';

interface YearProps {
    date: Date | string | number
}

export default function Year({ date }: YearProps) {

    const { texts } = useLocale();

    return (
        <Moment locale={texts.locale} format="YYYY">{new Date(date)}</Moment>
    );
}
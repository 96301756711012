import { useState, useEffect } from 'react';
import { Puff } from 'react-loader-spinner';
import styles from './Loading.module.css';
import { Line } from 'rc-progress';
import { useLocale } from '../hooks/useLocale';

interface SmallLoadingProps {
    hideMessage?: boolean,
    message?: string,
}

export function SmallLoading({ hideMessage = false, message }: SmallLoadingProps) {

    const { texts } = useLocale();

    return (
        <div className={[styles['loading-container'], styles['loading-container-small']].join(' ')}>
            <Puff color="#00BFFF" height={25} width={25} />
            {!hideMessage && <div className={styles['small-loading-message']}>{message ? message : texts.LOADING_MESSAGE}</div>}
        </div>
    );
}

interface MessageProps {
    message?: string
}

export function Message({ message }: MessageProps) {

    const { texts } = useLocale();

    return (
        <div className={styles['loading-container']}>
            <div className={styles['loading-message']}>{message ? message : texts.LOADING_MESSAGE_NOT_SET}</div>
        </div>
    );
}

interface LoadingProps {
    hideMessage?: boolean,
    message?: string,
    progress?: number,
    progressMessage?: string,
    smallMessage?: boolean
}

export function Loading({ hideMessage = false, message, progress, progressMessage, smallMessage = false }: LoadingProps) {
    const [loaderSize, setLoaderSize] = useState('100px');
    const { texts } = useLocale();

    const changeSize = () => {
        if (window.innerWidth > 600) {
            setLoaderSize('100px');
        } else {
            setLoaderSize('50px');
        }
    }

    useEffect(() => {
        changeSize();

        window.addEventListener("resize", changeSize);

        return () => {
            window.removeEventListener("resize", changeSize);
        };

    }, []);

    return (
        <div className={smallMessage ? styles['loading-container-small'] : styles['loading-container']}>
            <Puff color="#00BFFF" height={loaderSize} width={loaderSize} />
            {!hideMessage && <div className={smallMessage ? styles['loading-message-small'] : styles['loading-message']}>{message ? message : texts.LOADING_MESSAGE}</div>}
            {
                progress && progress < 100 && <div className={smallMessage ? styles['progress-wrapper-small'] : styles['progress-wrapper']}>
                    <Line className={styles['progress']} percent={progress} strokeWidth={1} strokeColor={"#1e90ff"} />
                    <div className={styles['progress-label']}>{progressMessage ? progressMessage : (Number(progress).toFixed(2) + '%')}</div>
                </div>
            }
        </div>
    );
}
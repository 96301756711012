import { useEffect } from 'react';
import { HomeHero, HomeFeatures, LynchRules } from '../features/home';
import { PageContainer } from '../layout/pageContainer/PageContainer';
import { useLocale } from '../hooks/useLocale';
import { Divider } from '../components/divider/Divider';

export default function HomeContainer() {

    const { texts } = useLocale();

    useEffect(() => {
        document.title = texts.HOME_PAGE_TITLE;
    }, [texts]);

    return (
        <PageContainer fullWidth>
            <HomeHero />
            <Divider light />
            <LynchRules />
            <HomeFeatures />
        </PageContainer>
    );
}
import styles from './MobileModalHeader.module.css';

interface MobileModalHeaderProps {
    children?: React.ReactNode
}

export default function MobileModalHeader({ children }: MobileModalHeaderProps) {
    return (
        <div className={styles['navbar-mobileModalHeader']}>{children}</div>
    );
}
import { Link } from "react-router-dom";
import styles from './NavBarMobile.module.css';
import links from '../../data/links.json';

interface NavBarMobileProps {
    onNavClick: () => void,
    onAccountClick: () => void
}

export default function NavBarMobile({ onNavClick, onAccountClick }: NavBarMobileProps) {
    return (
        <div className={styles['navbar-mobile']}>
            <div className={styles['navbar-menu']}>
                <div className={[styles['navbar-icon'], styles['navbar-mobileIcon']].join(' ')} onClick={onNavClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" focusable={false}>
                        <use href='#navbar-icon-menu' />
                    </svg>
                </div>
            </div>
            <Link className={styles['navbar-logo']} to={links.homeLink}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.15 68.46" focusable={false}>
                    <use href='#navbar-icon-logo' />
                </svg>
            </Link>
            <div className={styles['navbar-userProfile']}>
                <div className={[styles['navbar-icon'], styles['navbar-mobileIcon'], styles['navbar-userProfileIcon']].join(' ')} onClick={onAccountClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" focusable={false}>
                        <use href='#navbar-icon-userProfile' />
                    </svg>
                </div>
            </div>
        </div>
    );
}
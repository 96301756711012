import { useAuth } from 'react-oidc-context';
import NavBarModalItem from './NavBarModalItem';
import NavBarModalText from './NavBarModalText';
import NavBarModalButton from './NavBarModalButton';
import NavBarModalSection from './NavBarModalSection';
import MobileModalClose from './MobileModalClose';
import MobileModalHeader from './MobileModalHeader';
import NavBarMobileModalContainer from './NavBarMobileModalContainer';
import { useLocale } from '../../../hooks/useLocale';
import links from '../../../data/links.json';

interface AccountModalMobileProps {
    isOpen: boolean
    onCloseClick: () => void
}

export default function AccountModalMobile({ isOpen, onCloseClick }: AccountModalMobileProps) {
    const { texts } = useLocale();
    const { isAuthenticated, user, signinRedirect, signoutRedirect } = useAuth();

    if (isAuthenticated) {
        return (
            <NavBarMobileModalContainer isOpen={isOpen} position={'right'}>
                <MobileModalHeader></MobileModalHeader>
                <NavBarModalSection>
                    <NavBarModalText label={user?.profile.email} />
                </NavBarModalSection>
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelPortfoliosLink}`} label={texts.NAV_USER_PORTFOLIOS} />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelReportsLink}`} label={texts.NAV_USER_ADD_REPORT} isAdminOnly />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelInstrumentsLink}`} label={texts.NAV_USER_INSTRUMENTS} isAdminOnly />
                <NavBarModalItem link={`/${links.userPanelLink}/${links.userPanelStrategiesLink}`} label={texts.NAV_USER_STRATEGIES} isAdminOnly />
                <NavBarModalItem action={() => signoutRedirect()} label={texts.ACCOUNT_LOG_OUT} />
                <MobileModalClose position={'right'} onCloseClick={onCloseClick} />
            </NavBarMobileModalContainer>
        );
    }

    return (
        <NavBarMobileModalContainer isOpen={isOpen} position={'right'}>
            <MobileModalHeader></MobileModalHeader>
            <NavBarModalSection>
                <NavBarModalButton label={texts.ACCOUNT_LOG_IN} action={() => signinRedirect()} />
            </NavBarModalSection>
            <NavBarModalItem action={() => signinRedirect()} label={texts.ACCOUNT_CREATE_ACCOUNT} />
            <MobileModalClose position={"right"} onCloseClick={onCloseClick} />
        </NavBarMobileModalContainer>
    );
}
import HomeFeature from './HomeFeature';
import styles from './HomeFeatures.module.css';
import { useLocale } from '../../../hooks/useLocale';

export default function HomeFeatures() {
    const { texts } = useLocale();

    return (
        <section style={{ backgroundImage: 'url(/images/home/home-features-img.png' }} className={styles['container']}>
            {texts.home.features.map(item => {
                return (
                    <HomeFeature
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        linkTitle={item.linkTitle}
                        linkTo={item.linkTo}
                        img={item.img}
                        imgDescription={item.imgDescription}
                        imgLocation={item.imgLocation}
                        pictogram={item.pictogram}
                        list={item.list} />
                )
            })}
        </section>
    )
}
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styles from './NavBar.module.css';
import MobileNavIcons from './icons/MobileNavIcons';
import AccountModalDesktop from './modals/AccountModalDesktop';
import { instrumentEndpoint } from '../../lib/apiClient';
import StandardIcons from './icons/StandardIcons';
import MenuLogo from './icons/MenuLogo';
import NavBarMobile from './NavBarMobile';
import NavBarDesktop from './NavBarDesktop';
import AccountModalMobile from './modals/AccountModalMobile';
import NavigationModalMobile from './modals/NavigationModalMobile';
import SearchModalDesktop from './modals/SearchModalDesktop';
import { MenuType } from './MenuType';

export default function NavBar() {
    const location = useLocation();
    const [openedMenu, setOpenedMenu] = useState(MenuType.NO_MENU);
    const [instrumentsList, setInstrumentsList] = useState<FinancialInstrumentSelect[]>([]);
    const [instrumentsLoaded, setInstrumentsLoaded] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        setInstrumentsLoaded(false);
        instrumentEndpoint.getAll({signal: controller.signal})
            .then((response) => {
                const instrumentsList: FinancialInstrumentSelect[] = [];
                response.data.forEach((instrument: FinancialInstrument) => {
                    instrumentsList.push({ value: instrument.id, ticker: instrument.ticker, label: instrument.ticker + ' ' + instrument.name });
                })
                setInstrumentsList(instrumentsList);
                setInstrumentsLoaded(true);
            })
            .catch((error) => {
                console.error(error, error.message);
                setInstrumentsLoaded(true);
            });

        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setOpenedMenu(MenuType.NO_MENU);
    }, [location]);

    return (
        <nav className={(openedMenu === MenuType.MOBILE_ACCOUNT_MENU || openedMenu === MenuType.MOBILE_SITE_MENU || openedMenu === MenuType.DESKTOP_ACCOUNT_MENU || openedMenu === MenuType.DESKTOP_SEARCH_MENU) ? [styles['navbar'], styles['is-focused']].join(' ') : styles['navbar']}>
            <MobileNavIcons />
            <StandardIcons />
            <MenuLogo />
            <div className={styles['navbar-overlay']} onClick={() => setOpenedMenu(MenuType.NO_MENU)}></div>
            <div className={styles['navbar-container']}>
                <NavBarMobile
                    onNavClick={() => { setOpenedMenu(MenuType.MOBILE_SITE_MENU) }}
                    onAccountClick={() => { setOpenedMenu(MenuType.MOBILE_ACCOUNT_MENU) }}
                />
                <NavBarDesktop
                    openedMenu={openedMenu}
                    onAccountClick={() => { openedMenu === MenuType.DESKTOP_ACCOUNT_MENU ? setOpenedMenu(MenuType.NO_MENU) : setOpenedMenu(MenuType.DESKTOP_ACCOUNT_MENU) }}
                    onSearchClick={() => { openedMenu === MenuType.DESKTOP_SEARCH_MENU ? setOpenedMenu(MenuType.NO_MENU) : setOpenedMenu(MenuType.DESKTOP_SEARCH_MENU) }}
                />
            </div>
            <div className={styles['navbar-modals']}>
                <SearchModalDesktop isOpen={openedMenu === MenuType.DESKTOP_SEARCH_MENU} instrumentsLoaded={instrumentsLoaded} instrumentsList={instrumentsList} />
                <AccountModalDesktop isOpen={openedMenu === MenuType.DESKTOP_ACCOUNT_MENU} />
                <NavigationModalMobile isOpen={openedMenu === MenuType.MOBILE_SITE_MENU} instrumentsList={instrumentsList} onCloseClick={() => { setOpenedMenu(MenuType.NO_MENU); }} />
                <AccountModalMobile isOpen={openedMenu === MenuType.MOBILE_ACCOUNT_MENU} onCloseClick={() => { setOpenedMenu(MenuType.NO_MENU); }} />
            </div>
        </nav>
    );
}
import { Link } from 'react-router-dom';
import styles from './Button.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string,
    link?: string,
    active?: boolean
}

export const Button = ({ color, link, active = false, ...buttonProps } : ButtonProps) => {
    if (link) {
        return (
            <Link
                to={link}
                role={'link'}
                className={[styles['button'], active ? styles['active'] : null].join(' ')}
                style={color ? { backgroundColor: color } : {}}
            >{buttonProps.children}</Link>
        );
    } else {
        return (
            <button
                className={[styles['button'], active ? styles['active'] : null].join(' ')}
                style={color ? { backgroundColor: color } : {}}
                {...buttonProps}
            >{buttonProps.children}</button>
        );
    }
}

export const SmallButton = ({ color, link, active, ...buttonProps } : ButtonProps) => {
    if (link) {
        return (
            <Link
                to={link}
                role={'link'}
                className={[styles['button'], styles['button--small']].join(' ')}
                style={color ? { backgroundColor: color } : {}}
            >{buttonProps.children}</Link>
        );
    } else {
        return (
            <button
                className={[styles['button'], styles['button--small'], active ? styles['active'] : null].join(' ')}
                style={color ? { backgroundColor: color } : {}}
                {...buttonProps}
            >{buttonProps.children}</button>
        );
    }
}

export const VerySmallButton = ({ color, link, active, ...buttonProps } : ButtonProps) => {
    if (link) {
        return (
            <Link
                to={link}
                role={'link'}
                className={[styles['button'], styles['button--very-small']].join(' ')}
                style={color ? { backgroundColor: color } : {}}
            >{buttonProps.children}</Link>
        );
    } else {
        return (
            <button
                className={[styles['button'], styles['button--very-small'], active ? styles['active'] : null].join(' ')}
                style={color ? { backgroundColor: color } : {}}
                {...buttonProps}
            >{buttonProps.children}</button>
        );
    }
}

export const FullButton = ({ color, link, active, ...buttonProps } : ButtonProps) => {
    if (link) {
        return (
            <Link
                to={link}
                role={'link'}
                className={[styles['button'], styles['button--full']].join(' ')}
                style={color ? { backgroundColor: color } : {}}
            >{buttonProps.children}</Link>
        );
    } else {
        return (
            <button
                className={[styles['button'], styles['button--full'], active ? styles['active'] : null].join(' ')}
                style={color ? { backgroundColor: color } : {}}
                {...buttonProps}
            >{buttonProps.children}</button>
        );
    }
}
import { useAuth } from 'react-oidc-context';
import styles from '../NavBar.module.css';
import { userHasRole } from '../../../utils/PermissionChecker';

interface NavBarModalTextProps {
    label?: string,
    isAdminOnly?: boolean
}

export default function NavBarModalText({ label, isAdminOnly = false }: NavBarModalTextProps) {
    const { user } = useAuth();

    if (isAdminOnly) {
        if (!userHasRole(user?.access_token, process.env.REACT_APP_ADMIN_ROLE)) {
            return null;
        }
    }

    return (
        <span className={styles['navbar-modal-text']}>{label}</span>
    );
}
import { useEffect } from 'react';
import { useBooleanState, usePrevious } from 'webrix/hooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReactComponent as OfflineLogo } from '../../assets/logo.svg';
import 'animate.css';
import styles from './Offline.module.css';
import { useLocale } from '../../hooks/useLocale';

interface OfflineProps {
    children: React.ReactNode
}

export default function Offline({ children }: OfflineProps) {
    const { texts } = useLocale();
    const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);
    const previousOnline = usePrevious(online);

    useEffect(() => {
        if (!online) { return void disableBodyScroll(document.body); }

        enableBodyScroll(document.body);
    }, [online]);

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, [setOffline, setOnline]);

    return (
        <>
            <div
                className={[styles['offline'], 'animate__animated', 'animate__faster', online ? 'animate__slideOutUp' : 'animate__slideInDown'].join(' ')}
                style={previousOnline === online && online ? { display: 'none' } : void 0}
            >
                <div className={styles['offline__content']}>
                    <OfflineLogo />
                    <div className={styles['offline__text']}>
                        <p className="mt-0 mb-5">{texts.OFFLINE_WARNING_1}</p>
                        <p className="mt-0 mb-0">{texts.OFFLINE_WARNING_2}</p>
                    </div>
                </div>
            </div>
            <div className={[styles['offline__overlay'], !online ? styles['offline__overlay--visible'] : null].join(' ')} />
            {children}
        </>
    );
}